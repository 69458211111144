import { ApiService } from "./api.service";

export const getLocationPaqueteOFactura = (value) => {
  return new Promise(async resolve => {
    ApiService.get("location/" + value)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const SacarFactura = (salida) => {
  return new Promise(async resolve => {
    return ApiService.put("salida", salida)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const ObtenerTodosLosPaquetes = () => {
  return new Promise(async resolve => {
    ApiService.get("paquetes/all")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}