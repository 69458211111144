<template>
  <v-app>
      <div class="row">
        <div class="col-sm-12">
          <h2 class="font-weight-normal text-dark text-center">
            Nuestras
            <strong class="font-weight-extra-bold">Sucursales</strong>
          </h2>
        </div>
        <div
          class="col-md-4 col-sm-12"
          v-for="(gmp, index) in locations"
          :key="index"
        >
          <div class="card elevation-3">
            <div class="card-body bg-dark">
              <h4
                class="fw-bold text-center text-white"
                v-html="location_names[index]"
              ></h4>
            </div>
            <gmap-map
              :center="{ lat: gmp.lat, lng: gmp.lng }"
              :zoom="zoom"
              style="width: 100%; height: 350px"
            >
              <gmap-marker
                :position="gmp"
                :icon="markerOptions"
                @click="go(gmp)"
              ></gmap-marker>
            </gmap-map>
          </div>
        </div>
      </div>
  </v-app>
</template>
<script>
import * as miscService from "../../services/misc.module";
import * as mensajes from "../../helper/general.module";
export default {
  name: "Mapa",
  data() {
    return {
      //mapa
      center: {
        lat: 9.115062456315716,
        lng: -79.69422329601034,
      },
      zoom: 18,
      locations: [],
      location_names: [],
      sucursales: [],
      markerOptions: {
        url: window.location.origin + "/img/shipex/pin.png",
        size: { width: 30, height: 45, f: "px", b: "px" },
        scaledSize: { width: 30, height: 45, f: "px", b: "px" },
      },
    };
  },
  methods: {
    go(sucursal) {
      mensajes.toastrlong("Navegando hacia " + sucursal.label, "info");
      window.open(
        `https://www.waze.com/ul?ll=${sucursal.lat}%2C${sucursal.lng}&navigate=yes&zoom=17`,
        "_blank"
      );
    },
    setLocationLatLng: function () {
      navigator.geolocation.getCurrentPosition((geolocation) => {
        this.center = {
          lat: geolocation.coords.latitude,
          lng: geolocation.coords.longitude,
        };
      });
    },
    MapsMarkersLocations() {
      this.sucursales.forEach((suc) => {
        this.location_names.push(`${suc.nombre}<br/><span class="fs-16">${suc.referencia}</span>`);
        this.locations.push({
          lat: suc.lat,
          lng: suc.long,
          label: suc.nombre,
        });
      });
    },
    getSucursales() {
      miscService
        .GetSucursales()
        .then((sucursales) => {
          this.sucursales = sucursales;
          this.MapsMarkersLocations();
          this.setLocationLatLng();
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    this.getSucursales();
  },
};
</script>
