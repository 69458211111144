<template>
  <v-app>
    <v-card style="overflow: none" class="elevation-2">
      <v-card-title class="bg-dark text-white" primary-title>
        <v-col class="text-center p-0 font-weight-bold">
          Manejo de Rol Usuarios
        </v-col>
      </v-card-title>
      <form @submit.prevent="updaterolUser">
        <div class="p-3">
          <!-- Espacio para los inputs -->
          <div class="row">
            <div class="col">
              <label for="listUser" class="form-label">Usuario (Correo)</label>
              <input
                id="listUser"
                class="form-control"
                style="height: 50px"
                list="maillistOptions"
                v-model="selectedUser.correo"
                @change="desplegarUserInfo"
                placeholder="Escriba correo para buscar..."
                required
              />
              <datalist id="maillistOptions">
                <option
                  v-for="correo in correosUsers"
                  :key="correo.id"
                  :value="correo.text"
                />
              </datalist>
            </div>
            <div class="col">
              <label for="rol_input" class="form-label"
                >Rol
                <code class="text-danger" v-show="selectedUser.rol === ''"
                  >*Debe Seleccionar un Rol!*</code
                ></label
              >
              <v-select
                id="rol_input"
                solo
                :items="itemRol"
                v-model="selectedUser.rol"
              ></v-select>
            </div>
          </div>
        </div>

        <!-- Espacio para la información del Usuario -->

        <div id="infoUser" class="p-3" v-show="show">
          <h4 class="text-center">Información del Usuario</h4>
          <div class="row">
            <div class="col">
              <label for="nombreUser" class="form-label">Nombre</label>
              <input
                id="nombreUser"
                type="text"
                class="form-control mb-2"
                v-model="user.nombre"
                placeholder="Nombre de Usuario..."
                readonly
              />

              <label for="telefono" class="form-label">Teléfono</label>
              <input
                id="telefono"
                type="text"
                class="form-control mb-2"
                v-model="user.telefono"
                placeholder="Teléfono de Usuario..."
                readonly
              />
            </div>
            <div class="col">
              <label for="rolofUser" class="form-label">Rol de Usuario</label>
              <input
                id="rolofUser"
                type="text"
                class="form-control mb-2"
                v-model="user.rol"
                placeholder="Rol de Usuario..."
                readonly
              />

              <label for="correo" class="form-label">Correo</label>
              <input
                id="correo"
                type="text"
                class="form-control"
                v-model="user.correo"
                placeholder="Correo de Usuario..."
                readonly
              />

              <!-- <label for="accessUser" class="form-label">Acceso de Usuario</label>
            <input id="accessUser" type="text" class="form-control" v-model="user.accesos" placeholder="Acceso de Usuario..." readonly> -->
            </div>
          </div>

          <!-- Botones de Acción -->
          <div class="row">
            <div class="col">
              <input
                id="btn_save"
                type="submit"
                class="btn btn-success btn-block"
                value="Actualizar Rol"
                :disabled="selectedUser.rol === ''"
              />
            </div>
            <div class="col">
              <input
                id="btn_cancel"
                type="button"
                class="btn btn-primary btn-block"
                @click="limpiarSelectUser"
                value="Cancelar"
              />
            </div>
          </div>
        </div>
      </form>
    </v-card>
  </v-app>
</template>

<script>
import * as clientService from "../../services/clients.module.js";
import Swal from "sweetalert2";
// import * as auth from "../../services/auth.module.js";

export default {
  name: "ManejoRolUser",
  data() {
    return {
      show: false,
      selectedUser: {
        correo: "",
        rol: "",
      },
      user: {
        nombre: "",
        telefono: "",
        correo: "",
        rol: "",
        accesos: "",
      },
      correosUsers: [],
      itemRol: [],
      oneUserId: {},
    };
  },
  methods: {
    updaterolUser() {
      clientService
        .UpdateRolUser(this.selectedUser)
        .then((res) => {
          this.updateMessage(res);
          this.limpiarSelectUser();
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Algo ha salido mal!",
            footer: '<a href="">Contactar con el Desarrolador!</a>',
          });
        });
    },
    updateMessage(res) {
      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Datos Actualizados!",
          text: res.data.mensaje,
        });
      } else if (res.status == 400) {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: res.data.mensaje,
        });
      }
    },
    desplegarUserInfo() {
      if (this.selectedUser.correo != "") {
        this.show = true;
        this.correosUsers.filter((v) => {
          if (v.text === this.selectedUser.correo)
            this.getClients(String(v.id));
        });
      } else this.show = false;
    },
    limpiarSelectUser() {
      this.show = false;
      this.selectedUser = {
        correo: "",
        rol: "",
      };
      this.user = {
        nombre: "",
        telefono: "",
        correo: "",
        rol: "",
        accesos: "",
      };
    },
    getClients(accion) {
      clientService.GetAllClientesInfo(accion).then((clients) => {
        if (accion == "all") {
          clients.forEach((element) => {
            this.correosUsers.push({
              text: element.correo,
              id: element.id,
            });
          });
        } else {
          this.user.nombre = clients.nombre + " " + clients.apellido;
          this.user.telefono = clients.telefono;
          this.user.correo = clients.correo;
          this.itemRol.forEach((e) => {
            if (clients.rol == e.value) {
              this.user.rol = e.text;
            }
          });
        }
      });
    },
    getRolCliente() {
      clientService.GetRolCliente().then((rol) => {
        this.itemRol = rol;
      });
    },
  },
  mounted() {
    this.getClients("all");
    this.getRolCliente();
  },
};
</script>

<style></style>
