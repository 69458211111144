<template>
  <div>
      <form 
        id="formcontacto"
        method="post"
        @submit.prevent="Contactar"
        class="needs-validation"
        autocomplete="on">
        <div class="row">
          <div class="col-md-6 col-sm-12">
            <label for="name"><strong>Nombre</strong></label>
            <input id="name" type="text" class="form-control" v-model="contacto.nombre" required>
          </div>
          <div class="col-md-6 col-sm-12">
            <label for="company"><strong>Compañía</strong></label>
            <input id="company" type="text" class="form-control" v-model="contacto.compania" required>
          </div>
          <div class="col-md-6 col-sm-12">
            <label for="email"><strong>Correo Electronico</strong></label>
            <input id="email" type="email" class="form-control" v-model="contacto.correo" required>
          </div>
          <div class="col-md-6 col-sm-12">
            <label for="telefono"><strong>Teléfono</strong></label>
            <input id="telefono" type="text" class="form-control" v-model="contacto.telefono" pattern="[0-9]{1,}-[0-9]{1,}" title="1234-5678" required>
          </div>
          <div class="col-md-12 col-sm-12">
            <label for="telefono"><strong>Asunto</strong></label>
            <input id="motivo" type="text" class="form-control" v-model="contacto.motivo" placeholder="¿Motivo por el que nos contacta?" required>
          </div>
          <div class="col-md-12 col-sm-12">
            <label for="mensaje"><strong>Mensaje</strong></label>
            <textarea id="mensaje" rows="5" class="form-control" v-model="contacto.mensaje" placeholder="¿En qué podemos ayudarte?" required></textarea>
            <vue-recaptcha
            class="mt-2 d-flex justify-content-center"
            sitekey="6LfDYSEcAAAAAMh6eyhu5rJy3WTmzQsfShiPzIDT"
            :loadRecaptchaScript="true"
            @verify="validate"
            ></vue-recaptcha>
            <button class="mt-2 btn btn-block rojoShipex text-white" type="submit" :disabled="contacto.captcha === ''">Enviar</button>
          </div>
        </div>
      </form>
  </div>
</template>

<script>
import * as homeService from "../../services/auth.module.js";
import Swal from "sweetalert2";
import VueRecaptcha from "vue-recaptcha";

export default {
  name: "Contactenos",
  components: {
    VueRecaptcha
  },
  data() {
    return {
      contacto: {
        nombre: "",
        compania: "",
        correo: "",
        telefono: "",
        motivo: "",
        mensaje: "",
        captcha: ""
      }
    };
  },
  methods: {
    Contactar() {
      if (this.contacto.captcha) {
        Swal.fire({
          title: "Enviando mensaje...",
          html: "Por favor espere mientras enviamos sus datos a nuestros operadores!",
          timer: 3000,
          backdrop: false,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading();
            homeService
              .Contactar(this.contacto)
              .then((res) => {
                if (res.status == 200) {
                  Swal.fire({
                    icon: "success",
                    title: "Mensaje enviado!",
                    text: `Hemos enviado su mensaje a nuestros operadores, pronto sera contactado.`,
                    footer:
                      "<strong>Se ha enviado una copia de este mensaje a su correo.</strong>",
                  });
                  this.contacto = { captcha: "", motivo: "" };
                } else if ((res.status = 422)) {
                  var mensajes = general.ProcesarMensajesValidacion(res.data);
                  Swal.fire({
                    icon: "warning",
                    title: "Oops...",
                    text:
                      "Por favor, valide los siguientes valores: " + mensajes,
                  });
                } else {
                  Swal.fire({
                    icon: "error",
                    title: "Que mal.",
                    text: "Algo ha salido mal y no hemos podido enviar su mensaje.",
                  });
                }
              })
              .catch((error) => { console.log(error); });
          },
          willClose: () => {
            //$(".modal-backdrop").hide();
          },
        }).then((result) => {
          console.log(result);
        });
      } else {
        Swal.fire({
          toast: true,
          position: "bottom-end",
          showConfirmButton: false,
          icon: "error",
          title: "Aun no a realizado la verificación captcha",
          timer: 3000,
          timerProgressBar: true,
          didOpen: (toast) => {
            toast.addEventListener("mouseenter", Swal.stopTimer);
            toast.addEventListener("mouseleave", Swal.resumeTimer);
          },
        });
      }
    },
    validate(response) {
      this.contacto.captcha = response;
    },
  },
  mounted() {},
}
</script>

<style>

</style>