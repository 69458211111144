<template>
  <v-app>
    <v-card class="elevation-2">
      <v-card-title class="bg-dark text-white" primary-title>
        <v-col class="text-center p-0 font-weight-bold">
          Historial de Facturas
        </v-col>
      </v-card-title>
      <div class="p-3">
        <v-card-title>
          Lista de Facturas
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchFacturas"
            label="Buscador por coincidencias"
            append-icon="mdi-magnify"
            single-line
            hide-details
          ></v-text-field>
          <b-col md="2">
            <export-excel
              class="btn btn-success btn-block"
              :data="facturas"
              worksheet="Lista de Facturas"
              type="csv"
              name="lista_De_Facturas.xls"
            >
              <strong style="font-size: 18px"
                >Excel <i class="fas fa-file-download"></i
              ></strong>
            </export-excel>
          </b-col>
        </v-card-title>
        <v-data-table
          :headers="header"
          :items="facturas"
          :search="searchFacturas"
          class="border"
          :single-expand="true"
          :expanded.sync="expanded"
          show-expand
          :loading="facturas.length == 0"
          @click:row="rowClick"
          loading-text="Cargando... Por favor Espere!!"
        >
          <template v-slot:expanded-item="{ headers, item }">
            <td :colspan="headers.length">
              <table class="table table-bordered">
                <tr
                  v-for="prod in JSON.parse(item.items)"
                  v-bind:key="prod.description"
                >
                  <td>Producto: {{ prod.description }}</td>
                  <td>Cantidad: {{ prod.quantity }}</td>
                  <td>Precio: {{ prod.price }}</td>
                </tr>
              </table>
            </td>
          </template>
          <template v-slot:[`item.pagada`]="{ item }">
            <v-icon
              v-if="item.pagada == 0"
              medium
              class="mr-2 text-danger"
              v-b-tooltip.hover
              title="Por Cobrar"
            >
              mdi-block-helper
            </v-icon>
            <v-icon
              v-if="item.pagada == 1"
              medium
              class="mr-2 text-success"
              v-b-tooltip.hover
              title="Pagada"
            >
              mdi-check
            </v-icon>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import * as facturasService from "../../services/factura.module.js";
import * as auth from "../../services/auth.module.js";

export default {
  name: "Historialfacturas",
  data() {
    return {
      expanded: [],
      show: true,
      ctrledit: true,
      shownewPass: false,
      shownewAlegraId: false,
      spnactiveD: false,
      spnactiveCom: false,
      spnactiveR: false,
      spnactiveSPass: false,
      spnactiveInfo: false,
      searchFacturas: "",
      header: [
        { text: "Cliente", value: "cliente", align: "center" },
        { text: "Casillero", value: "casillero", align: "center" },
        { text: "Factura", value: "numero_factura", align: "center" },
        { text: "Sucursal", value: "sucursal", align: "center" },
        { text: "Fecha", value: "fecha", align: "center" },
        { text: "Total", value: "total" },
        { text: "Pagada", value: "pagada", align: "center" },
        { text: "Fecha Pago", value: "fecha_pago", align: "center" },
        { text: "Metodo de Pago", value: "metodo_pago", align: "center" },
        {
          text: "Día de Registro",
          value: "created_at",
          align: "center",
        },
        // { text: "Estado", value: null },
        { text: "Detalle", value: "data-table-expand", align: "center" },
      ],
      facturas: [],
      slUserbckp: {},
      fromFactura: "",
    };
  },
  methods: {
    verInfo(item) {
      this.slUserbckp = {};
      var data = JSON.stringify(item);
      this.selectUser = JSON.parse(data);
      this.slUserbckp = JSON.parse(data);
    },
    rowClick(value) {
      this.expanded = [];
      this.expanded.push(value);
    },
    getFacturas() {
      facturasService.GetAllFacturasNoFilter().then((facturas) => {
        this.facturas = facturas;
        var factura = new URL(window.location.href).searchParams.get("factura");
        if (factura) this.searchFacturas = factura;
      });
    },
  },
  mounted() {
    this.getFacturas();
  },
};
</script>

<style>
.btn-standar {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-standar:hover {
  color: #fff;
  background-color: #3496ff;
  border-color: #3496ff;
}
</style>
