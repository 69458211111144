<template>
  <v-app dark>
    <v-row>
      <div class="col-12 text-center">
        <div class="stat-card-shipex_primary">
          <div class="stat-card-left_content">
            <p class="stat-card-title m-0">
              Puntos <span class="font-weight-bold">Acumulados</span>
            </p>
            <small class="h2 counter-value fw-bold text-white">{{
              totales.total_libras_facturadas
            }}</small>
          </div>
          <div class="stat-card-right_content">
            <span
              class="stat-card-icon avatar-title rounded fs-3"
              style="background-color: #28a745 !important"
            >
              <i class="bx bx-coin-stack" style="color: #FFF"></i>
            </span>
          </div>
        </div>
      </div>
    </v-row>
  </v-app>
</template>

<script>
import * as clientService from "../../services/client.module";
import Swal from "sweetalert2";

export default {
  name: "Puntos",
  data() {
    return {
      totales: {
        total_libras_facturadas: 0,
      },
    };
  },
  methods: {
    getTotalLibras() {
      clientService
        .GetTotalLibras()
        .then((total) => {
          this.totales = total;
        })
        .catch((err) => console.log(err));
    },
  },
  mounted() {
    this.getTotalLibras();
  },
};
</script>

<style>
.v-application--wrap {
  min-height: auto !important;
}
.theme--light.v-application {
  background: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.87);
}
@media screen and (max-width: 1024px) {
  .v-dialog {
    margin-top: 100px;
  }
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 80%;
  }
}
</style>