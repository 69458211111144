import axios from "axios";


export const ApiService = axios.create({
  baseURL: window.location.origin + '/',
  headers: {
    'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    'X-Requested-With': 'XMLHttpRequest'
  }
});

export const EnviamexService = axios.create({
  //baseURL: 'https://enviamexusa.com/api/',
  baseURL: 'http://localhost:8001/api/',
  headers: {
    //'X-CSRF-TOKEN': document.querySelector('meta[name="csrf-token"]').getAttribute('content'),
    //'X-Requested-With': 'XMLHttpRequest' //esperar a validar a ver si se eliminan
    'Api-Key': '286c9064-866f-4555-87df-40bca389abca'
  }
});

export default { ApiService, EnviamexService };
