<template>
  <v-app>
      <h2 class="font-weight-normal text-dark text-center">
        Tiendas Online
        <strong class="font-weight-extra-bold">Sugeridas</strong>
      </h2>
      <div class="row text-center mt-5 pb-5 mb-5" role="principal">
        <Carousel :autoplay="true" :nav="false" :items="items()">
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Aliexpress.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Amazon.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Boxycharm.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Carters.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Colourpop.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Ebay.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Etsy.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/HotTopic.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Ipsy.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Macys.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Rakuten.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Shein.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/VictoriaSecrets.png"
              alt=""
            />
          </div>
          <div>
            <img
              class="img-fluid"
              src="/img/shipex/logos/sites/black/Wish.png"
              alt=""
            />
          </div>
        </Carousel>
      </div>
  </v-app>
</template>
<script>
import Carousel from "vue-owl-carousel";

export default {
  name: "FootrCarouselBlack",
  components: { Carousel },
  data() {
    return {
      items: () => {
        if (screen.width > 992) {
          return 5;
        }
        return 2;
      },
    };
  },
};
</script>