<template>
  <div class="container">
    <div class="row text-center pt-4 mt-5">
      <div class="col">
        <h2 class="word-rotator slide font-weight-bold text-8 mb-2">
          <span style="color: white">Es </span>
          <span class="word-rotator-words bg-danger rojoShipex">
            <b class="is-visible">Increíble</b>
            <b>Confiable</b>
            <b>Rápido</b>
            <b>Seguro</b>
          </span>
          <span style="color: white"> comprar con Shipex </span>
        </h2>
      </div>
    </div>

    <div class="row text-center mt-5 pb-5 mb-5" role="principal">
      <Carousel :autoplay="true" :nav="false" :items="6">
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Aliexpress.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Amazon.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Boxycharm.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Carters.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Colourpop.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Ebay.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Etsy.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/HotTopic.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Ipsy.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Macys.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Rakuten.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Shein.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/VictoriaSecrets.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Wallmart.png" alt="" />
        </div>
        <div>
          <img class="img-fluid" src="/img/shipex/logos/sites/white/Wish.png" alt="" />
        </div>
      </Carousel>
    </div>
  </div>
</template>
<script>
import Carousel from 'vue-owl-carousel'

export default {
  name:"FootrCarouselWhite",
  components: { Carousel }
}
</script>