<template>
  <v-app>
      <div class="card shadow border-0">
        <h4 class="card-title text-white bg-dark h4 p-4">
          REGISTRO Y CONTROL DE RASTREO DE MIS PAQUETES
        </h4>
        <div class="card-body text-dark">
          <div class="row">
            <!-- Message -->
            <div class="col-12">
              <form
                id="preAlertaForm"
                @submit.prevent="BuscarOPreAlertaPaquete"
              >
                <div class="row">
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="tracking" class="form-label"
                        >Numero de seguimiento (Tracking)</label
                      >
                      <input
                        id="tracking"
                        name="tracking"
                        v-model="preAlertado.tracking"
                        class="form-control"
                        type="text"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="packageValue" class="form-label"
                        >Valor de compra del paquete (en USD)</label
                      >
                      <input
                        id="packageValue"
                        name="valor_paquete"
                        v-model="preAlertado.valor_paquete"
                        class="form-control"
                        type="number"
                        min="0.01"
                        step=".01"
                        required
                      />
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="courier" class="form-label"
                        >Compañía que entrega el paquete</label
                      >
                      <v-select
                        id="courier"
                        v-model="preAlertado.courier"
                        :items="itemsCouriers"
                        item-text="text"
                        item-value="value"
                        solo
                      ></v-select>
                    </div>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group">
                      <label for="description" class="form-label">Descripción del producto</label>
                      <textarea
                        id="description"
                        name="descripcion"
                        v-model="preAlertado.descripcion"
                        class="form-control"
                        rows="5"
                        maxlength="100"
                        required
                      ></textarea>
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center">
                  <button
                    class="btn btn-primary"
                    type="submit"
                    form="preAlertaForm"
                    :disabled="showProgress"
                  >
                    Buscar / Pre Alertar
                    <v-progress-circular
                      size="20"
                      width="3"
                      indeterminate
                      color="white"
                      v-if="showProgress"
                    ></v-progress-circular>
                  </button>
                </div>
              </form>
            </div>
            <hr class="my-3 "/>
              <h6 class="fw-bold mb-3">LISTA DE PAQUETES RASTREADOS</h6>
            <div class="col-12">
              <v-data-table
                v-model="selected"
                :search="search"
                :headers="headers"
                :items="items"
                class="elevation-2"
                item-key="id"
              >
                <template v-slot:top>
                  <div class="row">
                    <div class="col-md-6 col-12">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Search"
                        class="ml-2"
                        clearable
                        hide-details
                      ></v-text-field>
                    </div>
                  </div>
                </template>
              </v-data-table>
            </div>
          </div>
        </div>
      </div>
      <div
        class="modal fade"
        id="mdlConfirmarDel"
        tabindex="-1"
        role="dialog"
        data-backdrop="static"
      >
        <div class="modal-dialog modal-dialog-centered" role="document">
          <div class="modal-content">
            <div class="modal-header">
              <h5 class="modal-title">Confirma Eliminación</h5>
              <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
              ></button>
            </div>
            <div class="modal-body" v-if="selected.length">
              <h5 class="text-dark">
                Está seguro de eliminar el siguiente Tracking
              </h5>
              <p>
                Tracking:
                <span id="lblTrackToDel">{{ selected[0].tracking }}</span>
                con Descripción:
                <span id="lblDescripToDel">{{ selected[0].descripcion }}</span>
              </p>
            </div>
            <div class="modal-footer">
              <button
                type="button"
                class="btn btn-secondary"
                data-dismiss="modal"
              >
                Cerrar
              </button>
              <button
                id="btnSiEliminarTracking"
                href="#"
                class="btn btn-outline-dangerPgt"
                @click="deleteSaveTracking(selected[0].id)"
              >
                Eliminar tracking
                <v-progress-circular
                  size="20"
                  width="3"
                  indeterminate
                  color="red"
                  v-if="showProgress"
                ></v-progress-circular>
              </button>
            </div>
          </div>
        </div>
      </div>
  </v-app>
</template>
<script>
import * as enviamexService from "../../services/enviamex";
import * as clientService from "../../services/client.module";
import * as GeneralHelper from "../../helper/general.module";

export default {
  name: "Rastreoyprealertas",
  data() {
    return {
      preAlertado: {
        tracking: null,
        courier: 1,
        tienda: "Shipex Panama",
        valor_paquete: 0,
        descripcion: "",
      },
      search: null,
      itemsCouriers: [],
      selected: [],
      headers: [
        {
          text: "Registrado",
          value: "created_at",
        },
        {
          text: "Tracking",
          value: "tracking",
        },
        {
          text: "Descripción",
          value: "descripcion",
        },
        {
          text: "Estado",
          value: "estado",
        },
        {
          text: "",
          value: "actions",
        },
      ],
      items: [],
      showProgress: false,
      formData: null,
    };
  },
  props: ["user"],
  methods: {
    BuscarOPreAlertaPaquete() {
      this.formData = new FormData(document.getElementById("preAlertaForm"));
      this.formData.append(
        "cliente_info",
        `SHIPEX ${this.user.cliente.nombre} ${this.user.cliente.apellido}`
      );
      this.formData.append(
        "cliente_code",
        `SHIPEX ${this.user.cliente.tipo_plan}${this.user.cliente.casillero}`
      );
      this.formData.append("cliente_mail", "info.shipexpa@gmail.com");
      this.formData.append("courier", this.preAlertado.courier);
      this.formData.append("tienda", "SHIPEX PANAMA");
      this.showProgress = true;
      GeneralHelper.toastrlong("Guardando, por favor espere...", "info");
      enviamexService
        .CreatePreAlertaUSA(this.formData)
        .then((res) => {
          this.preAlertado.invoice_file_route = res.data.invoice_file_route
            ? res.data.invoice_file_route
            : "ruta";
          clientService
            .GuardarRastreo(this.preAlertado)
            .then((res) => {
              this.showProgress = false;
              if (res.status == 201) {
                this.preAlertado = {
                  tracking: null,
                  courier: 1,
                  tienda: "Shipex Panama",
                  valor_paquete: 0,
                  descripcion: "",
                };
                this.getRastreos();
              }
              GeneralHelper.ShowMessages(res);
            })
            .catch((res) => {
              console.log(res);
            });
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getCouriers() {
      enviamexService
        .GetCouiriers()
        .then((res) => {
          this.itemsCouriers = res;
        })
        .catch((res) => {
          console.log(res);
        });
    },
    getRastreos() {
      clientService
        .Rastreos()
        .then((res) => {
          this.items = res;
          //this.UpdateStatusPreAlertas();
        })
        .catch((res) => {
          console.log(res);
        });
    },
    deleteSaveTracking(saved_package_id) {
      this.showProgress = true;
      ClientPreAlertaServices.DeleteSaveTracking(saved_package_id)
        .then((res) => {
          this.selected = [];
          this.showProgress = false;
          $("#mdlConfirmarDel").modal("toggle");
          this.getSavedPackage();
          this.$root.$emit("StadisticsUpdate");
          GeneralHelper.ShowMessages(res);
        })
        .catch((res) => {
          console.log(res);
        });
    },
    UpdateStatusPreAlertas() {
      this.items.forEach((t) => {
        if (t.estado_id !== "4" /*&& t.where_in != "Entregado"*/) {
          webServices
            .GetInfoTrackingUSA(t.tracking)
            .then((res) => {
              if (res.status == 200) {
                var newInfo = res.data;
                t.where_in = newInfo.where_in;
                t.miami_arrive_date = newInfo.miami_arrive_date;
                t.panama_arrive_date = newInfo.panama_arrive_date;
                ClientPreAlertaServices.UpdateSavedPackage(newInfo);
              }
            })
            .catch((err) => console.log(err));
        }
      });
    },
  },
  mounted() {
    this.getCouriers();
    this.getRastreos();
  },
};
</script>