/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

window.Vue = require('vue').default;

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('example-component', require('./components/ExampleComponent.vue').default);
//Common Components
Vue.component('Fcarouselw', require('./components/comun/FootrCarouselWhite.vue').default);
Vue.component('Fcarouselb', require('./components/comun/FootrCarouselBlack.vue').default);

//Web Components
Vue.component('Noticias', require('./components/web/Noticias.vue').default);
Vue.component('Carousel', require('./components/web/Carousel.vue').default);
Vue.component('Calculadora', require('./components/web/Calculadora.vue').default);
Vue.component('trackPaquetes', require('./components/web/trackPaquetes.vue').default);
Vue.component('Politicas', require('./components/web/Politicas.vue').default);
Vue.component('Contactenos', require('./components/web/Contactenos.vue').default);
Vue.component('Bannercarrousel', require('./components/web/Bannercarrousel.vue').default);


//Auth Components
Vue.component('Registro', require('./components/auth/Registro.vue').default);
Vue.component('Resetpassword', require('./components/auth/Resetpassword.vue').default);
Vue.component('Changepassword', require('./components/auth/ChangePassword.vue').default);

//Admin Components
Vue.component('Userinfo', require('./components/admin/UserInfo.vue').default);
Vue.component('Manejoroluser', require('./components/admin/ManejoRolUser.vue').default);
Vue.component('Adminrolusers', require('./components/admin/Adminrolusers.vue').default);
Vue.component('Paquetesbodega', require('./components/admin/PaquetesBodega.vue').default);
Vue.component('Facturacion', require('./components/admin/Facturacion.vue').default);
Vue.component('Historialfacturas', require('./components/admin/Historialfacturas.vue').default);
Vue.component('Pagos', require('./components/admin/Pagos.vue').default);
Vue.component('adminheader', require('./components/admin/AdminHeader.vue').default);
Vue.component('Cierrefactura', require('./components/admin/CierreFactura.vue').default);
Vue.component('PaquetesInBodega', require('./components/admin/PaquetesBodega.vue').default);
Vue.component('Homeeditimages', require('./components/admin/Homeeditimages.vue').default);
Vue.component('Homestats', require('./components/admin/Homestats.vue').default);
Vue.component('Admintarifas', require('./components/admin/Admintarifas.vue').default);
Vue.component('Adminpromociones', require('./components/admin/Adminpromociones.vue').default);

//User Components
Vue.component('Facturas', require('./components/user/Facturas.vue').default);
Vue.component('Puntos', require('./components/user/Puntos.vue').default);
Vue.component('Mapa', require('./components/user/Mapa.vue').default);
Vue.component('Edituser', require('./components/user/Perfil.vue').default);
Vue.component('Profilepic', require('./components/user/Profilepic.vue').default);
Vue.component('Tipofacturacion', require('./components/user/Tipofacturacion.vue').default);
Vue.component('Prealertas', require('./components/user/Prealertas.vue').default);
Vue.component('Promocion', require('./components/user/Promocion.vue').default);
/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */
import excel from 'vue-excel-export'
Vue.use(excel)

import '@mdi/font/css/materialdesignicons.css'
import 'vuetify/dist/vuetify.min.css'
import Vuetify from 'vuetify'
Vue.use(Vuetify);

import 'vue-toast-notification/dist/theme-sugar.css';
import VueToast from 'vue-toast-notification';
Vue.use(VueToast)

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import BootstrapVue from 'bootstrap-vue'
import Vue from 'vue';
Vue.use(BootstrapVue)

import * as VueGoogleMaps from "vue2-google-maps"
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAdp3Rr_nTP2VGFS2rMgOvkG9TQVwqRZ-Q",
    libraries: "places"
  }
});

const app = new Vue({
  el: '#app',
  vuetify: new Vuetify(),
});