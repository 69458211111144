<template>
  <v-app>
    <div class="container-fluid">
      <div class="position-relative mx-n4 mt-n4">
        <div class="profile-wid-bg profile-setting-img">
          <img
            src="/assets/images/profile-bg.jpg"
            class="profile-wid-img"
            alt=""
          />
        </div>
      </div>

      <div class="row">
        <div class="col-xxl-3">
          <div class="card mt-n5">
            <div class="card-body p-4">
              <div class="text-center">
                <div
                  class="
                    profile-user
                    position-relative
                    d-inline-block
                    mx-auto
                    mb-4
                  "
                >
                  <Profilepic :profile="this.foto"></Profilepic>
                </div>
                <h5 class="fs-17 mb-1">
                  {{ form.nombre }} {{ form.apellido }}
                </h5>
                <p class="text-muted mb-0">Cliente / {{ form.cedula }}</p>
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
        <div class="col-xxl-9">
          <div class="card mt-xxl-n5">
            <div class="card-header">
              <ul
                class="
                  nav nav-tabs-custom
                  rounded
                  card-header-tabs
                  border-bottom-0
                "
                role="tablist"
              >
                <li class="nav-item">
                  <a
                    class="nav-link active"
                    data-bs-toggle="tab"
                    href="#personalDetails"
                    role="tab"
                  >
                    <i class="fas fa-home"></i>
                    Personal Details
                  </a>
                </li>
                <li class="nav-item">
                  <a
                    class="nav-link"
                    data-bs-toggle="tab"
                    href="#changePassword"
                    role="tab"
                  >
                    <i class="far fa-user"></i>
                    Change Password
                  </a>
                </li>
              </ul>
            </div>
            <div class="card-body p-4">
              <div class="tab-content">
                <div
                  class="tab-pane fade active show"
                  id="personalDetails"
                  role="tabpanel"
                >
                  <form @submit.prevent="actualizarDatosUser()">
                    <div class="row">
                      <div class="col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="firstnameInput" class="form-label"
                            >Nombre</label
                          >
                          <input
                            v-model="form.nombre"
                            type="text"
                            class="form-control"
                            id="firstnameInput"
                            disabled
                            required
                          />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="lastnameInput" class="form-label"
                            >Apellido</label
                          >
                          <input
                            class="form-control"
                            id="lastnameInput"
                            v-model="form.apellido"
                            type="text"
                            disabled
                            required
                          />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="phonenumberInput" class="form-label"
                            >Numero de telefono</label
                          >
                          <input
                            id="phonenumberInput"
                            class="form-control"
                            v-model="form.telefono"
                            type="text"
                            :disabled="ctrledit"
                            required
                          />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="emailInput" class="form-label">
                            Correo</label
                          >
                          <input
                            id="emailInput"
                            class="form-control"
                            v-model="form.correo"
                            type="email"
                            disabled
                            required
                          />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="cedula" class="form-label">Cedula</label>
                          <input
                            class="form-control"
                            id="cedula"
                            v-model="form.cedula"
                            type="text"
                            disabled
                            required
                          />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="designationInput" class="form-label"
                            >Fecha de Nacimiento</label
                          >
                          <input
                            class="form-control"
                            id="designationInput"
                            v-model="form.fecha_nacimiento"
                            type="date"
                            disabled
                            required
                          />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="input-plan" class="form-label"
                            >Tipo de plan</label
                          >
                          <b-form-select
                            id="input-plan"
                            name="input-plan"
                            v-model="form.tipo_plan"
                            :options="options"
                            class="mt-0 pt-0"
                            disabled
                            required
                          ></b-form-select>
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-6 col-sm-12">
                        <div class="mb-3">
                          <label for="websiteInput1" class="form-label"
                            >Casillero</label
                          >
                          <input
                            class="form-control"
                            id="websiteInput1"
                            v-model="form.casillero"
                            type="text"
                            disabled
                            required
                          />
                        </div>
                      </div>
                      <!--end col-->
                      <div class="col-lg-12">
                        <div class="hstack gap-2 justify-content-center">
                          <b-button
                            id="editUser"
                            variant="secondary"
                            @click="editUserOn()"
                            :hidden="ctrledit != true"
                            style="width: 12rem"
                            >Editar
                            <em class="ri-edit-2-line"></em>
                          </b-button>
                          <b-button
                            id="cancelEdit"
                            variant="secondary"
                            class="mr-2"
                            @click="cancelEditUser()"
                            :hidden="ctrledit"
                            style="width: 12rem"
                            >Cancelar <em class="far fa-times-circle"></em
                          ></b-button>
                          <b-button
                            id="saveEdit"
                            variant="success"
                            type="submit"
                            :hidden="ctrledit"
                            style="width: 12rem"
                            >Guardar
                            <em class="ri-save-2-line"></em>
                            <b-spinner
                              small
                              v-if="spnactive"
                              label="Spinning"
                            ></b-spinner
                          ></b-button>
                        </div>
                      </div>
                      <!--end col-->
                    </div>
                    <!--end row-->
                  </form>
                </div>
                <!--end tab-pane-->
                <div class="tab-pane fade" id="changePassword" role="tabpanel">
                  <b-form @submit="actualizarPass" v-if="show">
                    <div class="row text-dark">
                      <div class="col-md-6 col-sm-12">
                        <b-form-group
                          id="newPass"
                          label="Nueva Contraseña"
                          label-for="input-newPass"
                          label-class="font-weight-bold"
                        >
                          <b-input-group class="mt-3">
                            <b-form-input
                              class="rounded-start"
                              id="input-newPass"
                              v-model="newData.newPass"
                              :type="btn1.type"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                variant="secondary"
                                v-html="btn1.icon"
                                @click="showPassword(1)"
                                class="rounded-end"
                              ></b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </div>
                      <div class="col-md-6 col-sm-12">
                        <b-form-group
                          id="newPass"
                          label="Verificar Contraseña"
                          label-class="font-weight-bold"
                          label-for="input-newPass"
                        >
                          <b-input-group class="mt-3">
                            <b-form-input
                              id="input-newPass2"
                              class="rounded-start"
                              v-model="pass2"
                              :type="btn2.type"
                            ></b-form-input>
                            <b-input-group-append>
                              <b-button
                                variant="secondary"
                                v-html="btn2.icon"
                                @click="showPassword(2)"
                                class="rounded-end"
                              ></b-button>
                            </b-input-group-append>
                          </b-input-group>
                        </b-form-group>
                      </div>
                      <div class="col-sm-12"></div>
                    </div>

                    <b-col sm="12">
                      <b-button
                        block
                        variant="success"
                        class="text-white"
                        type="submit"
                        :disabled="verificarPass()"
                        >Actualizar
                        <em class="ri-save-2-line"></em>
                        <b-spinner
                          small
                          v-if="spnactive"
                          label="Spinning"
                        ></b-spinner>
                      </b-button>
                    </b-col>
                  </b-form>
                </div>
                <!--end tab-pane-->
              </div>
            </div>
          </div>
        </div>
        <!--end col-->
      </div>
      <!--end row-->
    </div>
  </v-app>
</template>

<script>
import * as clientService from "../../services/clients.module.js";

export default {
  name: "Edituser",
  data() {
    return {
      show: true,
      ctrledit: true,
      pass2: "",
      cndPass: true,
      spnactive: false,
      form: {
        id: "",
        nombre: "",
        apellido: "",
        correo: "",
        cedula: "",
        telefono: "",
        fecha_nacimiento: "",
        tipo_plan: "",
        casillero: "",
        profile_pic: "",
      },
      options: [
        { value: "ST", text: "Sin Tarjeta" },
        { value: "E", text: "Económico" },
      ],
      btn1: {
        type: "password",
        icon: '<i class="ri-eye-line"></i>',
      },
      btn2: {
        type: "password",
        icon: '<i class="ri-eye-line"></i>',
      },
      newData: {
        id: "",
        newPass: "",
      },
    };
  },
  props: ["user", "foto"],
  methods: {
    showPassword(data) {
      if (data == 1) {
        if (this.btn1.type === "password") {
          this.btn1.type = "text";
          this.btn1.icon = '<i class="ri-eye-off-line"></i>';
        } else {
          this.btn1.type = "password";
          this.btn1.icon = '<i class="ri-eye-line"></i>';
        }
      }

      if (data == 2) {
        if (this.btn2.type === "password") {
          this.btn2.type = "text";
          this.btn2.icon = '<i class="ri-eye-off-line"></i>';
        } else {
          this.btn2.type = "password";
          this.btn2.icon = '<i class="ri-eye-line"></i>';
        }
      }
    },
    verificarPass() {
      if (
        this.newData.newPass == this.pass2 &&
        this.newData.newPass != "" &&
        this.pass2 != ""
      ) {
        return false;
      }
      return true;
    },
    getClient() {
      clientService.GetInfoCliente(this.user.cliente_id).then((result) => {
        this.form = result;
        this.form.profile_pic = window.location.origin + result.profile_pic;
      });
    },
    actualizarDatosUser() {
      this.spnactive = true;
      clientService.UpdateUserData(this.form).then((result) => {
        if (result.status == 200) {
          Vue.$toast.open({
            message: "<span class='text-white'>" + result.message + "</span>",
            type: "success",
            duration: 10000,
          });
          this.ctrledit = true;
          this.spnactive = false;
        } else {
          Vue.$toast.open({
            message: "<span class='text-white'>" + result.message + "</span>",
            type: "error",
            duration: 10000,
          });
        }
      });
    },
    actualizarPass(event) {
      event.preventDefault();
      this.newData.id = this.form.id;
      this.spnactive = true;
      clientService.UpdateUserPass(this.newData).then((result) => {
        if (result.status == 200) {
          Vue.$toast.open({
            message: "<span class='text-white'>" + result.message + "</span>",
            type: "success",
            duration: 10000,
          });
          this.spnactive = false;
        } else {
          Vue.$toast.open({
            message: "<span class='text-white'>" + result.message + "</span>",
            type: "error",
            duration: 10000,
          });
          this.spnactive = false;
        }
      });

      this.newData = { id: "", newPass: "" };
      this.pass2 = "";
    },
    editUserOn() {
      this.ctrledit = false;
    },
    cancelEditUser() {
      this.ctrledit = true;
    },
  },
  mounted() {
    this.getClient();
  },
};
</script>

<style>
.btn-standar {
  color: #fff;
  background-color: #007bff;
  border-color: #007bff;
}

.btn-standar:hover {
  color: #fff;
  background-color: #3496ff;
  border-color: #3496ff;
}

#perfil_foto > .tamañoPic {
  width: auto !important;
  height: auto !important;
}
</style>
