import { ApiService } from "./api.service";

export const GetAdminStats = (month) => {
  return new Promise(async resolve => {
    ApiService.get("statsby/" + month)
      .then((response) => {
        resolve(response.data);
        return response.data;
      })
      .catch(({ response }) => {
        console.log(response);
      });
  });
}

export const GetAdminStatsAdvance = (month) => {
  return new Promise(async resolve => {
    ApiService.get("stats/advance/" + month)
      .then((response) => {
        resolve(response.data);
        return response.data;
      })
      .catch(({ response }) => {
        console.log(response);
      });
  });
}