<template>
  <div>
    <form
      id="formregist"
      method="post"
      @submit.prevent="registrar"
      class="needs-validation"
      autocomplete="on"
    >
      <div class="row">
        <div class="form-group col-md-6">
          <label for="nombre" class="col-form-label text-md-right"
            >Nombre(s).</label
          >
          <input
            id="nombre"
            type="text"
            class="form-control"
            name="nombre"
            required
            autofocus
            v-model="registro.nombre"
            @keyup="validarRuta"
          />
          <div class="invalid-feedback">Nombre requerido.</div>
        </div>
        <div class="form-group col-md-6">
          <label for="apellido" class="col-form-label text-md-right"
            >Apellido(s)</label
          >
          <input
            id="apellido"
            type="text"
            class="form-control"
            name="apellido"
            required
            autofocus
            v-model="registro.apellido"
          />
          <div class="invalid-feedback">Apellido requerido.</div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="fechaNacimiento" class="col-form-label text-md-right"
            >Fecha de Nacimiento</label
          >
          <input
            id="fechaNacimiento"
            type="date"
            class="form-control"
            v-model="registro.fechaNacimiento"
            @change="validarEdad"
            name="fechaNacimiento"
            required
            autofocus
          />
          <div class="invalid-feedback">Fecha de Nacimiento requerida.</div>
        </div>
        <div class="form-group col-md-6">
          <label for="Cedula" class="col-form-label text-md-right"
            >Cédula / Pasaporte</label
          >
          <input
            id="cedula"
            v-model="registro.cedula"
            type="text"
            class="form-control"
            onkeyup="this.value = this.value.toUpperCase();"
            name="cedula"
            pattern="([0-9|E|P|I]{1,3}-[0-9]{1,6}-[0-9]{1,6})|([A-Z0-9]{9})"
            required
          />
          <div class="invalid-feedback">Cédula requerida.</div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="telefono" class="col-form-label text-md-right"
            >Telefono</label
          >
          <input
            id="telefono"
            type="text"
            class="form-control"
            name="telefono"
            pattern="[0-9]{1,}-[0-9]{1,}"
            required
            autofocus
            v-model="registro.telefono"
          />
          <div class="invalid-feedback">Teléfono requerido.</div>
        </div>
        <div class="form-group col-md-6">
          <label for="tipoPlan" class="col-form-label text-md-right"
            >Tipo de Plan</label
          >
          <b-form-select
            id="tipoPlan"
            name="tipoPlan"
            v-model="registro.tipoPlan"
            :options="options"
            class="mt-0 pt-0"
            required
          ></b-form-select>
          <div class="invalid-feedback">Tipo de Plan requerido.</div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="email" class="col-form-label text-md-right">Correo</label>
          <input
            id="email"
            type="email"
            class="form-control"
            name="email"
            @change="validarCorreo"
            required
            v-model="registro.correo"
          />
          <div class="invalid-feedback">Correo electrónico requerido.</div>
        </div>
        <div class="form-group col-md-6">
          <label for="emailConfirm" class="col-form-label text-md-right"
            >Confirmar Correo</label
          >
          <input
            id="emailConfirm"
            type="emailConfirm"
            class="form-control"
            name="emailConfirm"
            @change="validarCorreo"
            required
            v-model="registro.correoConfirm"
          />
          <div class="invalid-feedback">
            Confirmación de Correo eletrónico requerido.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="form-group col-md-6">
          <label for="password" class="col-form-label text-md-right"
            >Contraseña</label
          >
          <input
            id="password"
            type="password"
            class="form-control"
            @change="validarPass"
            required
            v-model="registro.contrasena"
          />
          <div class="invalid-feedback">Contraseña requerida.</div>
        </div>

        <div class="form-group col-md-6">
          <label for="password-confirm" class="col-form-label text-md-right"
            >Confirmar Contraseña</label
          >
          <input
            id="password-confirm"
            type="password"
            class="form-control"
            @change="validarPass"
            required
            v-model="registro.contrasenaConfirm"
          />
          <div class="invalid-feedback">
            Confirmación de contraseña requerida.
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col mb-1">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="registro.terminos"
              value="acepto"
              id="terms_cond"
              name="terms_cond"
              required
            />
            <label class="form-check-label" for="terms_cond">
              Aceptar
              <a href="https://www.shipexpanama.com/politicas/" target="_BLANK"
                >Términos y Condiciones</a
              >
            </label>
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col d-flex justify-content-center">
          <vue-recaptcha
            sitekey="6LfDYSEcAAAAAMh6eyhu5rJy3WTmzQsfShiPzIDT"
            :loadRecaptchaScript="true"
            @verify="validate"
          ></vue-recaptcha>
        </div>
      </div>

      <div class="row mb-0">
        <div class="col d-flex justify-content-center">
          <button
            type="submit"
            class="btn w-50 rojoShipex"
            :disabled="registro.captcha === ''"
          >
            Registrar
          </button>
        </div>
      </div>
    </form>
  </div>
</template>
<script>
import VueRecaptcha from "vue-recaptcha";
import * as authService from "../../services/auth.module.js";
import * as miscService from "../../services/misc.module.js";
import moment from "moment";
import Swal from "sweetalert2";
export default {
  name: "Registro",
  components: {
    VueRecaptcha,
  },
  data() {
    return {
      edad_persona: 0,
      registro: {
        tipoPlan: "E",
        captcha: "",
      },
      options: [],
    };
  },
  props: ["link"],
  methods: {
    registrar() {
      this.validarRuta();
      if (this.edad_persona < 18) {
        Swal.fire({
          icon: "error",
          title: "¡Error!",
          allowOutsideClick: false,
          text: "¡Tienes que ser mayor de edad para Registrate!",
        });
      } else {
        if (this.registro.terminos == true) {
          this.registro.terminos = 1;
        }
        Swal.fire({
          title: "Registrando Usuario!",
          html: '<div class="progress m-4"><div aria-valuenow="0" class="progress-bar bg-danger" style="width: 0%;"></div></div><b></b>',
          timer: 0,
          showConfirmButton: false,
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
            const content = Swal.getHtmlContainer();
            if (content) {
              const b = content.querySelector("b");
              const pg = content.getElementsByClassName("progress-bar");

              b.textContent = "Verificando datos o_o!!";
              setInterval(() => {
                pg[0].ariaValueNow = 2 + parseInt(pg[0].ariaValueNow);
                pg[0].style.cssText = "width: " + pg[0].ariaValueNow + "%;";
              }, 40);
              setTimeout(() => {
                b.textContent = "Registrando ^_^ !!";
              }, 6000);
            }
          },
          willClose: () => {
            Swal.fire({
              icon: "success",
              title: "¡Éxito!",
              allowOutsideClick: false,
              confirmButtonText: "Iniciar Sesión",
              text: "Usuario registrado correctamente",
              confirmButtonColor: "#cc2027",
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href = this.link;
              }
            });
          },
        });
        authService
          .RegistrarCliente(this.registro)
          .then((registro) => {
            if (registro.status == 200) {
              $("#formregist").trigger("reset");
              Swal.close();
            } else if (registro.status == 422) {
              var message = "";
              console.log(registro.data.errors);
              Object.entries(registro.data.errors).forEach((prop) => {
                // console.log(prop);
                prop[1].forEach((m) => {
                  // console.log(m);
                  message += m + "\n";
                });
              });
              Swal.fire({
                icon: "warning",
                title: "¡Ups, favor validar!",
                allowOutsideClick: false,
                text: message,
              });
            }
          })
          .catch((errors) => {
            console.log(errors);
            Swal.fire({
              icon: "error",
              title: "¡Error!",
              allowOutsideClick: false,
              text: "Ha ocurrido un error inesperado, favor ponerte en contacto con el personal de Shipex desde este enlace!",
            });
          });
      }
    },
    validarEdad() {
      $("#fechaNacimiento").tooltip("dispose");
      var birth_date = moment(this.registro.fechaNacimiento).format("YYYY");
      this.edad_persona = new Date().getFullYear() - Number(birth_date);
      if (this.edad_persona < 18) {
        $("#fechaNacimiento").tooltip({
          title: "Tiene que ser Mayor de Edad",
          placement: "right",
          delay: { hide: 30000 },
        });
      }
    },
    validarCorreo() {
      $("#emailConfirm").tooltip("dispose");
      var normal = $("#email").val();
      var confirmar = $("#emailConfirm").val();
      if (normal == confirmar) {
        $("#emailConfirm").tooltip({
          title: "Correos iguales ✓",
          placement: "right",
          delay: { hide: 30000 },
        });
      } else {
        $("#emailConfirm").tooltip({
          title: "Correos no son iguales X",
          placement: "right",
          delay: { hide: 30000 },
        });
      }
    },
    validarPass() {
      $("#password-confirm").tooltip("dispose");
      var pass1 = $("#password").val();
      var pass2 = $("#password-confirm").val();
      if (pass1 == pass2) {
        $("#password-confirm").tooltip({
          title: "Contraseñas iguales ✓",
          placement: "right",
          delay: { hide: 30000 },
        });
      } else {
        $("#password-confirm").tooltip({
          title: "Contraseñas no son iguales X",
          placement: "right",
          delay: { hide: 30000 },
        });
      }
    },
    validarRuta() {
      if (location.pathname.search("/E") != "-1") {
        this.tipoPlan = "E";
        document.getElementById("tipoPlan").value = "E";
      } else if (location.pathname.search("/ST") != "-1") {
        this.tipoPlan = "ST";
        document.getElementById("tipoPlan").value = "ST";
      }
    },
    validate(response) {
      this.registro.captcha = response;
    },
    obtenerPlanes() {
      miscService.GetPlanesList().then(res => {
        this.options = res.filter(element =>element.value.search('C') < 0);
      });
    },
  },
  mounted() {
    //this.registrarUsuarioPersona();
    this.obtenerPlanes();
    this.validarRuta();
  },
};
</script>
