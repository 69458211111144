import { ApiService } from "./api.service";

export const GetImages = () => {
  return new Promise(async resolve => {
    ApiService.get("images")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}


export const UploadImages = (ImageData) => {
  return new Promise(async resolve => {
    ApiService.post("images/upload", ImageData, { header: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const UpdateImages = (ImageData) => {
  return new Promise(async resolve => {
    ApiService.post("images/update", ImageData, { header: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const DeleteImage = (ImageId) => {
  return new Promise(async resolve => {
    ApiService.delete("images/delete/" + ImageId)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}