import { ApiService } from "./api.service";

export const GetImagesByFilter = (typeImage) => {
  return new Promise(async resolve => {
    ApiService.get("image/" + typeImage)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetSucursales = () => {
  return new Promise(async resolve => {
    ApiService.get("sucursales")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetPlanesList = () => {
  return new Promise(async resolve => {
    ApiService.get("planes")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}