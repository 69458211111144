<template>
  <v-app dark>
    <v-row>
      <v-col md="12">
        <span class=" h5 font-weight-bold text-white">Plan utilizado:</span> {{ tipo.nombre_plan }}
        <v-dialog
          id="dialogTF"
          v-model="dialog"
          transition="dialog-bottom-transition"
          max-width="800"
          persistent
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn small dark color="#db253b" v-bind="attrs" v-on="on"
              ><v-icon> mdi-pencil </v-icon></v-btn
            >
          </template>
          <template>
            <v-card>
              <v-toolbar color="#db253b" dark
                >Cambio De Tipo De Facturación</v-toolbar
              >
              <v-card-text>
                <div class="row">
                  <div class="col-md-12 mt-5">
                    <v-stepper v-model="e1">
                      <v-stepper-header>
                        <v-stepper-step
                          :complete="e1 > 1"
                          step="1"
                          color="#db253b"
                        >
                          Seleccionar Plan #1
                        </v-stepper-step>

                        <v-divider></v-divider>

                        <v-stepper-step
                          :complete="e1 > 2"
                          step="2"
                          color="#db253b"
                        >
                          Confirmar Cambio #2
                        </v-stepper-step>
                      </v-stepper-header>

                      <v-stepper-items>
                        <v-stepper-content step="1">
                          <h5 class="text-center">
                            PAN ACTUAL: {{ tipoFacturacion(client.tipo_plan) }}
                          </h5>
                          <v-expansion-panels
                            dark
                            v-model="panel"
                            class="my-5"
                            focusable
                            :readonly="diasRest != 0"
                          >
                            <v-expansion-panel
                              :key="0"
                              :readonly="panel == 0"
                              v-show="filter == 0"
                            >
                              <v-expansion-panel-header>
                                <v-col md="2" class="p-0"
                                  ><v-icon>mdi-widgets</v-icon></v-col
                                >
                                Económico Peso/Volumen
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col
                                  >Casillero personalizado en Miami y dirección
                                  para carga aérea. Además incluye salidas
                                  semanales y servicio de retorno. Bl/.2.50 por
                                  libra/volumen</v-col
                                >
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                              :key="1"
                              :readonly="panel == 1"
                              v-show="filter == 1"
                            >
                              <v-expansion-panel-header>
                                <v-col md="2" class="p-0"
                                  ><v-icon>mdi-widgets</v-icon></v-col
                                >Sin Tarjeta Peso/Volumen
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col
                                  >Realizamos tu compra por ti con nuestra
                                  tarjeta de crédito, con garantía de compra
                                  incluida. Además, obtienes beneficios de
                                  Amazon Prime. Bl/.3.25 por
                                  libra/volumen</v-col
                                >
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                              :key="2"
                              :readonly="panel == 2"
                              v-show="filter == 0"
                            >
                              <v-expansion-panel-header>
                                <v-col md="2" class="p-0"
                                  ><v-icon>mdi-widgets</v-icon></v-col
                                >Económico Sin Volumen
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col
                                  >¡En este tipo de plan se tomará para efectos
                                  de facturación el peso real del paquete! Sin
                                  mínimo de facturación a un costo de Bl/.3.00
                                  por libra.</v-col
                                >
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel
                              :key="3"
                              :readonly="panel == 3"
                              v-show="filter == 1"
                            >
                              <v-expansion-panel-header>
                                <v-col md="2" class="p-0"
                                  ><v-icon>mdi-widgets</v-icon></v-col
                                >Sin tarjeta Sin Volumen
                              </v-expansion-panel-header>
                              <v-expansion-panel-content>
                                <v-col
                                  >¡En este tipo de plan se tomará para efectos
                                  de facturación el peso real del paquete! Sin
                                  mínimo de facturación a un costo de Bl/.3.50
                                  por libra</v-col
                                >
                              </v-expansion-panel-content>
                            </v-expansion-panel>
                          </v-expansion-panels>
                          <div class="row">
                            <div class="col-md-12" v-if="diasRest == 0">
                              <v-btn
                                class="text-white"
                                color="#db253b"
                                @click="assignData"
                                :disabled="panel == planActual"
                              >
                                Continue
                              </v-btn>
                              <strong
                                >*SOLO PUEDE HACER EL CAMBIO 1 VEZ CADA 30
                                DIAS*.</strong
                              >
                            </div>
                            <div class="col-md-12" v-else>
                              <strong
                                >*EN {{ diasRest }} DIAS PODRA CAMBIAR EL PLAN
                                NUEVAMENTE*.</strong
                              >
                            </div>
                          </div>
                        </v-stepper-content>

                        <v-stepper-content step="2">
                          <v-card
                            class="mb-12"
                            color="lighten-3 grey"
                            height="200px"
                          >
                            <div class="p-3 text-justify">
                              esta seguro que desea cambiar el plan actual
                              <strong
                                >*{{
                                  tipoFacturacion(client.tipo_plan)
                                }}*</strong
                              >
                              por el plan
                              <strong>*{{ tipoFacturacion(panel) }}*</strong>.
                            </div>
                            <br />
                            <div class="p-3 text-center">
                              Le recordamos que
                              <strong
                                >*SOLO PUEDE HACER EL CAMBIO 1 VEZ CADA 30
                                DIAS*.</strong
                              >
                            </div>
                          </v-card>

                          <v-btn dark color="#db253b" @click="guardarCambios">
                            Confirmar
                          </v-btn>

                          <v-btn @click="e1 = 1"> Volver </v-btn>
                        </v-stepper-content>
                      </v-stepper-items>
                    </v-stepper>
                  </div>
                </div>
              </v-card-text>
              <v-card-actions class="justify-end">
                <v-btn @click="cancelProcess" block>Cancelar</v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import * as clientService from "../../services/client.module";
import * as generalHelper from "../../helper/general.module";
import Swal from "sweetalert2";

export default {
  name: "Tipofacturacion",
  data() {
    return {
      dialog: false,
      panel: 0,
      planActual: 0,
      e1: 1,
      diasRest: 0,
      data: {
        tipo_plan: "",
        plan_fecha: "",
        client_id: "",
      },
      filter: "",
    };
  },
  props: ["client", "tipo"],
  methods: {
    guardarCambios() {
      this.dialog = false;
      Swal.fire({
        showConfirmButton: false,
        icon: "info",
        toast: true,
        timer: 3000,
        position: "bottom-end",
        title: "Procesando Cambio de Plan!⏰",
      });
      clientService
        .UpdatePlanClient(this.data)
        .then((res) => {
          generalHelper.ShowMessagesToastr(res);
          this.cancelProcess();
          window.location.reload();
        })
        .catch((res) => {
          console.log(res);
        });
    },
    verifyDate() {
      const date = new Date(`${this.client.plan_fecha} 00:00:00`);
      const date2 = new Date();
      date2.setHours(0, 0, 0, 0);
      if (date < date2) {
        this.diasRest = 0;
      } else {
        const diffDays = Math.ceil(
          Math.abs(date2 - date) / (1000 * 60 * 60 * 24)
        );
        this.diasRest = diffDays;
      }
    },
    cancelProcess() {
      this.dialog = false;
      this.selectType(this.client.tipo_plan);
      this.e1 = 1;
      this.verifyDate();
      this.data = {
        plan_id: "",
      };
    },
    assignData() {
      this.e1 = 2;
      let date = new Date();
      date.setDate(date.getDate() + 30);
      this.data.plan_fecha = date.toISOString().split("T")[0];
      this.data.client_id = this.client.id;
      if (this.panel == 0) {
        this.data.tipo_plan = "E";
      } else if (this.panel == 1) {
        this.data.tipo_plan = "ST";
      } else if (this.panel == 2) {
        this.data.tipo_plan = "EP";
      } else if (this.panel == 3) {
        this.data.tipo_plan = "STP";
      }
    },
    selectType(value) {
      if (value == "E") {
        this.panel = 0;
        this.planActual = 0;
      } else if (value == "ST") {
        this.panel = 1;
        this.planActual = 1;
      } else if (value == "EP") {
        this.panel = 2;
        this.planActual = 2;
      } else if (value == "STP") {
        this.panel = 3;
        this.planActual = 3;
      }
    },
    tipoFacturacion(tipo) {
      if (tipo == "E" || tipo == 0) {
        this.filter = 0;
        return "Económico Peso/Volumen";
      } else if (tipo == "ST" || tipo == 1) {
        this.filter = 1;
        return "Sin Tarjeta Peso/Volumen";
      } else if (tipo == "EP" || tipo == 2) {
        this.filter = 0;
        return "Económico Sin Volumen";
      } else {
        this.filter = 1;
        return "Sin tarjeta Sin Volumen";
      }
    },
  },
  mounted() {
    this.selectType(this.client.tipo_plan);
    this.verifyDate();
  },
};
</script>

<style>
.v-application--wrap {
  min-height: auto !important;
}
.theme--light.v-application {
  background: rgba(255, 255, 255, 0);
  color: rgba(255, 255, 255, 0.87);
}
@media screen and (max-width: 1024px) {
  .v-dialog {
    margin-top: 100px;
  }
  .v-dialog:not(.v-dialog--fullscreen) {
    max-height: 80%;
  }
}
</style>