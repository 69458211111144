<template>
    <div id="newBanner" class="row text-center">
      <div class="col-md-12 d-flex mx-0">
      <b-carousel
          id="carousel"
          :interval="10000"
          class="w-100"
          controls
        >
          <b-carousel-slide v-for="i in banners" :key="i.id" :img-src="i.url_file" :img-alt="i.alt_name">
          </b-carousel-slide>
        </b-carousel>
    </div>
  </div>
</template>

<script>
import * as miscelaneaServices from "../../services/misc.module";

export default {
  name: "Bannercarrousel",
  data() {
    return {
      banners: [],
    };
  },
  methods: {
    getBanners() {
      miscelaneaServices.GetImagesByFilter("banner_superior").then((res) => {
        this.banners = res;
      });
    }
  },
  mounted(){
    this.getBanners();
  }
};
</script>

<style>
</style>