<template>
  <div>
    <h3 style="color: white" class="text-center">Cambiar contraseña</h3>
    <br />
    <form
      method="post"
      id="formresetPass"
      @submit.prevent="updateNewPass"
      class="needs-validation"
      autocomplete="on"
    >
      <div class="row">
        <div class="col">
          <b-form-group label="Nueva contraseña:" label-for="password">
            <b-form-input
              id="password"
              v-model="form.pass1"
              type="password"
              placeholder="Introduzca su contraseña nueva..."
              required
            ></b-form-input>
          </b-form-group>
          <b-form-group label="Verificar contraseña:" label-for="password">
            <b-form-input
              id="password"
              v-model="form.pass2"
              type="password"
              placeholder="Repita su contraseña nueva..."
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-7 col-md-offset-2">
          <b-button
            type="submit"
            block
            class="rojoShipex mb-3"
            :disabled="form.pass1 != form.pass2"
            >Actualizar Contraseña
            <b-spinner small v-if="spnactive" label="Spinning"></b-spinner>
          </b-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import * as authService from "../../services/auth.module.js";
import Swal from "sweetalert2";
export default {
  name: "ChangePassword",
  data() {
    return {
      spnactive: false,
      form: {
        pass1: "",
        pass2: "",
      },
    };
  },
  props: ["cliente_id"],
  methods: {
    updateNewPass() {
      this.spnactive = true;
      this.form.cliente_id = this.cliente_id;
      authService
        .UpdatePassRecovery(this.form)
        .then((resetPass) => {
          if (resetPass.status == 200) {
            $("#formresetPass").trigger("reset");
            Swal.fire({
              icon: "success",
              title: "¡Actualizacion de Contraseña!",
              allowOutsideClick: false,
              text: resetPass.message,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href =
                  "https://www.shipexpanama.com/login";
              }
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "¡Aviso!",
              allowOutsideClick: false,
              text: resetPass.message,
            });
          }
        })
        .catch((errors) => {
          Swal.fire({
            icon: "error",
            title: "¡Error!",
            allowOutsideClick: false,
            text: "Ha ocurrido un error inesperado, favor ponerte en contacto con el personal de Shipex!",
          });
        });
    },
  },
  mounted() {
    // console.log("Montado");
  },
};
</script>

<style>
</style>