import { ApiService } from "./api.service";

export const GetTarifas = () => {
  return new Promise(async resolve => {
    ApiService.get("tarifas")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetPlanesListForTarifa = () => {
  return new Promise(async resolve => {
    ApiService.get("tarifa/planes")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const RegistrarNuevaTarifa = (tarifa) => {
  return new Promise(async resolve => {
    ApiService.post("tarifa",tarifa)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const ActualizarTarifa = (tarifa) => {
  return new Promise(async resolve => {
    ApiService.put("tarifa",tarifa)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}
export const ActualizarEstadoTarifa = (tarifa) => {
  return new Promise(async resolve => {
    ApiService.put("tarifa/estado",tarifa)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetPlanesWithTarifa = () => {
  return new Promise(async resolve => {
    ApiService.get("tarifas/planes")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}