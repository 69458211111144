<template>
  <div>
    <header id="page-topbar">
      <div class="layout-width">
        <div class="navbar-header">
          <div class="d-flex">
            <!-- LOGO -->
            <div class="navbar-brand-box horizontal-logo">
              <a :href="host" class="logo logo-dark">
                <span class="logo-sm">
                  <img src="/assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span class="logo-lg">
                  <img src="/assets/images/logo-dark.png" alt="" height="17" />
                </span>
              </a>

              <a :href="host" class="logo logo-light">
                <span class="logo-sm">
                  <img src="/assets/images/logo-sm.png" alt="" height="22" />
                </span>
                <span class="logo-lg">
                  <img src="/assets/images/logo-light.png" alt="" height="17" />
                </span>
              </a>
            </div>

            <button
              type="button"
              class="btn btn-sm px-3 fs-16 header-item vertical-menu-btn topnav-hamburger"
              id="topnav-hamburger-icon"
            >
              <span class="hamburger-icon">
                <span></span>
                <span></span>
                <span></span>
              </span>
            </button>
          </div>

          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <div class="ms-1 header-item d-none d-sm-flex">
                <button
                  type="button"
                  class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                  data-toggle="fullscreen"
                >
                  <i class="bx bx-fullscreen fs-22"></i>
                </button>
              </div>
            </div>

            <div class="dropdown topbar-head-dropdown header-item">
              <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="bx bx-search fs-22"></i>
              </button>
              <div
                class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <v-card class="p-3"
                  ><v-text-field
                    v-model="searchTracking"
                    :append-outer-icon="searchTracking ? 'mdi-card-search' : ''"
                    clear-icon="mdi-close-circle"
                    clearable
                    label="Buscador de Tracking directo"
                    type="text"
                    autofocus
                    @click:append-outer="SearchTracking"
                    @click:clear="searchTracking == ''"
                  ></v-text-field
                ></v-card>
              </div>
            </div>

            <div
              class="dropdown topbar-head-dropdown ms-1 header-item"
              id="notificationDropdown"
            >
              <button
                type="button"
                class="btn btn-icon btn-topbar btn-ghost-secondary rounded-circle"
                id="page-header-notifications-dropdown"
                data-bs-toggle="dropdown"
                data-bs-auto-close="outside"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <i class="bx bx-bell fs-22"></i>
                <span
                  class="position-absolute topbar-badge fs-10 translate-middle badge rounded-pill bg-danger"
                  >3<span class="visually-hidden">unread messages</span></span
                >
              </button>
              <div
                class="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-notifications-dropdown"
              >
                <div class="dropdown-head bg-primary bg-pattern rounded-top">
                  <div class="p-3">
                    <div class="row align-items-center">
                      <div class="col">
                        <h6 class="m-0 fs-16 fw-semibold text-white">
                          Notifications
                        </h6>
                      </div>
                      <div class="col-auto dropdown-tabs">
                        <span class="badge badge-soft-light fs-13"> 4 New</span>
                      </div>
                    </div>
                  </div>

                  <div class="px-2 pt-2">
                    <ul
                      class="nav nav-tabs dropdown-tabs nav-tabs-custom"
                      data-dropdown-tabs="true"
                      id="notificationItemsTab"
                      role="tablist"
                    >
                      <li class="nav-item waves-effect waves-light">
                        <a
                          class="nav-link active"
                          data-bs-toggle="tab"
                          href="#all-noti-tab"
                          role="tab"
                          aria-selected="true"
                        >
                          All (4)
                        </a>
                      </li>
                      <li class="nav-item waves-effect waves-light">
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#messages-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Messages
                        </a>
                      </li>
                      <li class="nav-item waves-effect waves-light">
                        <a
                          class="nav-link"
                          data-bs-toggle="tab"
                          href="#alerts-tab"
                          role="tab"
                          aria-selected="false"
                        >
                          Alerts
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div
                  class="tab-content position-relative"
                  id="notificationItemsTabContent"
                >
                  <div
                    class="tab-pane fade show active py-2 ps-2"
                    id="all-noti-tab"
                    role="tabpanel"
                  >
                    <div data-simplebar style="max-height: 300px" class="pe-2">
                      <div
                        class="text-reset notification-item d-block dropdown-item position-relative"
                      >
                        <div class="d-flex">
                          <div class="avatar-xs me-3">
                            <span
                              class="avatar-title bg-soft-info text-info rounded-circle fs-16"
                            >
                              <i class="bx bx-badge-check"></i>
                            </span>
                          </div>
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-2 lh-base">
                                Your <b>Elite</b> author Graphic Optimization
                                <span class="text-secondary">reward</span>
                                is ready!
                              </h6>
                            </a>
                            <p
                              class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                            >
                              <span
                                ><i class="mdi mdi-clock-outline"></i> Just 30
                                sec ago</span
                              >
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <div class="form-check notification-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="all-notification-check01"
                              />
                              <label
                                class="form-check-label"
                                for="all-notification-check01"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="text-reset notification-item d-block dropdown-item position-relative"
                      >
                        <div class="d-flex">
                          <img
                            src="/assets/images/users/avatar-2.jpg"
                            class="me-3 rounded-circle avatar-xs"
                            alt="user-pic"
                          />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                Angela Bernier
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                Answered to your comment on the cash flow
                                forecast's graph 🔔.
                              </p>
                            </div>
                            <p
                              class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                            >
                              <span
                                ><i class="mdi mdi-clock-outline"></i> 48 min
                                ago</span
                              >
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <div class="form-check notification-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="all-notification-check02"
                              />
                              <label
                                class="form-check-label"
                                for="all-notification-check02"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="text-reset notification-item d-block dropdown-item position-relative"
                      >
                        <div class="d-flex">
                          <div class="avatar-xs me-3">
                            <span
                              class="avatar-title bg-soft-danger text-danger rounded-circle fs-16"
                            >
                              <i class="bx bx-message-square-dots"></i>
                            </span>
                          </div>
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-2 fs-13 lh-base">
                                You have received
                                <b class="text-success">20</b> new messages in
                                the conversation
                              </h6>
                            </a>
                            <p
                              class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                            >
                              <span
                                ><i class="mdi mdi-clock-outline"></i> 2 hrs
                                ago</span
                              >
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <div class="form-check notification-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="all-notification-check03"
                              />
                              <label
                                class="form-check-label"
                                for="all-notification-check03"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="text-reset notification-item d-block dropdown-item position-relative"
                      >
                        <div class="d-flex">
                          <img
                            src="/assets/images/users/avatar-8.jpg"
                            class="me-3 rounded-circle avatar-xs"
                            alt="user-pic"
                          />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                Maureen Gibson
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                We talked about a project on linkedin.
                              </p>
                            </div>
                            <p
                              class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                            >
                              <span
                                ><i class="mdi mdi-clock-outline"></i> 4 hrs
                                ago</span
                              >
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <div class="form-check notification-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="all-notification-check04"
                              />
                              <label
                                class="form-check-label"
                                for="all-notification-check04"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="my-3 text-center view-all">
                        <button
                          type="button"
                          class="btn btn-soft-success waves-effect waves-light"
                        >
                          View All Notifications
                          <i class="ri-arrow-right-line align-middle"></i>
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    class="tab-pane fade py-2 ps-2"
                    id="messages-tab"
                    role="tabpanel"
                    aria-labelledby="messages-tab"
                  >
                    <div data-simplebar style="max-height: 300px" class="pe-2">
                      <div
                        class="text-reset notification-item d-block dropdown-item"
                      >
                        <div class="d-flex">
                          <img
                            src="/assets/images/users/avatar-3.jpg"
                            class="me-3 rounded-circle avatar-xs"
                            alt="user-pic"
                          />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                James Lemire
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                We talked about a project on linkedin.
                              </p>
                            </div>
                            <p
                              class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                            >
                              <span
                                ><i class="mdi mdi-clock-outline"></i> 30 min
                                ago</span
                              >
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <div class="form-check notification-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="messages-notification-check01"
                              />
                              <label
                                class="form-check-label"
                                for="messages-notification-check01"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="text-reset notification-item d-block dropdown-item"
                      >
                        <div class="d-flex">
                          <img
                            src="/assets/images/users/avatar-2.jpg"
                            class="me-3 rounded-circle avatar-xs"
                            alt="user-pic"
                          />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                Angela Bernier
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                Answered to your comment on the cash flow
                                forecast's graph 🔔.
                              </p>
                            </div>
                            <p
                              class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                            >
                              <span
                                ><i class="mdi mdi-clock-outline"></i> 2 hrs
                                ago</span
                              >
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <div class="form-check notification-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="messages-notification-check02"
                              />
                              <label
                                class="form-check-label"
                                for="messages-notification-check02"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="text-reset notification-item d-block dropdown-item"
                      >
                        <div class="d-flex">
                          <img
                            src="/assets/images/users/avatar-6.jpg"
                            class="me-3 rounded-circle avatar-xs"
                            alt="user-pic"
                          />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                Kenneth Brown
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                Mentionned you in his comment on 📃 invoice
                                #12501.
                              </p>
                            </div>
                            <p
                              class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                            >
                              <span
                                ><i class="mdi mdi-clock-outline"></i> 10 hrs
                                ago</span
                              >
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <div class="form-check notification-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="messages-notification-check03"
                              />
                              <label
                                class="form-check-label"
                                for="messages-notification-check03"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="text-reset notification-item d-block dropdown-item"
                      >
                        <div class="d-flex">
                          <img
                            src="/assets/images/users/avatar-8.jpg"
                            class="me-3 rounded-circle avatar-xs"
                            alt="user-pic"
                          />
                          <div class="flex-1">
                            <a href="#!" class="stretched-link">
                              <h6 class="mt-0 mb-1 fs-13 fw-semibold">
                                Maureen Gibson
                              </h6>
                            </a>
                            <div class="fs-13 text-muted">
                              <p class="mb-1">
                                We talked about a project on linkedin.
                              </p>
                            </div>
                            <p
                              class="mb-0 fs-11 fw-medium text-uppercase text-muted"
                            >
                              <span
                                ><i class="mdi mdi-clock-outline"></i> 3 days
                                ago</span
                              >
                            </p>
                          </div>
                          <div class="px-2 fs-15">
                            <div class="form-check notification-check">
                              <input
                                class="form-check-input"
                                type="checkbox"
                                value=""
                                id="messages-notification-check04"
                              />
                              <label
                                class="form-check-label"
                                for="messages-notification-check04"
                              ></label>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="my-3 text-center view-all">
                        <button
                          type="button"
                          class="btn btn-soft-success waves-effect waves-light"
                        >
                          View All Messages
                          <i class="ri-arrow-right-line align-middle"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    class="tab-pane fade p-4"
                    id="alerts-tab"
                    role="tabpanel"
                    aria-labelledby="alerts-tab"
                  ></div>

                  <div class="notification-actions" id="notification-actions">
                    <div class="d-flex text-muted justify-content-center">
                      Select
                      <div
                        id="select-content"
                        class="text-body fw-semibold px-1"
                      >
                        0
                      </div>
                      Result
                      <button
                        type="button"
                        class="btn btn-link link-danger p-0 ms-3"
                        data-bs-toggle="modal"
                        data-bs-target="#removeNotificationModal"
                      >
                        Remove
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="dropdown ms-sm-3 header-item topbar-user">
              <button
                type="button"
                class="btn"
                id="page-header-user-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <span class="d-flex align-items-center">
                  <img
                    class="rounded-circle header-profile-user"
                    src="/img/shipex/logoLetter.png"
                    alt="Header Avatar"
                  />
                  <span class="text-start ms-xl-2">
                    <span
                      class="d-none d-xl-inline-block ms-1 fw-medium user-name-text"
                      >{{ cliente.nombre }} {{ cliente.apellido }}</span
                    >
                    <span
                      class="d-none d-xl-block ms-1 fs-12 text-muted user-name-sub-text"
                      >{{ rol.nombre }}</span
                    >
                  </span>
                </span>
              </button>
              <div class="dropdown-menu dropdown-menu-end">
                <!-- item-->
                <h6 class="dropdown-header">
                  Bienvenido {{ cliente.nombre }}!
                </h6>
                <a class="dropdown-item" :href="routes.logout"
                  ><i
                    class="mdi mdi-logout text-muted fs-16 align-middle me-1"
                  ></i>
                  <span class="align-middle" data-key="t-logout"
                    >Cerrar sesión</span
                  ></a
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <div class="app-menu navbar-menu">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <!-- Dark Logo-->
        <a :href="host" class="logo logo-dark">
          <span class="logo-sm">
            <img src="/img/shipex/logoLetter.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="/img/shipex/logoBlack.png" alt="" height="49" />
          </span>
        </a>
        <!-- Light Logo-->
        <a href="https://www.shipexpanama.com/" class="logo logo-light">
          <span class="logo-sm">
            <img src="/img/shipex/logoLetter.png" alt="" height="22" />
          </span>
          <span class="logo-lg">
            <img src="/img/shipex/logoBlack.png" alt="" height="49" />
          </span>
        </a>

        <button
          type="button"
          class="btn btn-sm p-0 fs-20 header-item float-end btn-vertical-sm-hover"
          id="vertical-hover"
        >
          <i class="ri-record-circle-line"></i>
        </button>
      </div>

      <div id="scrollbar">
        <div class="container-fluid">
          <div id="two-column-menu"></div>
          <ul class="navbar-nav" id="navbar-nav">
            <li class="menu-title">
              <span data-key="t-menu">Opciones del Sistema</span>
            </li>
            <li class="nav-item">
              <a
                class="nav-link menu-link"
                v-if="validacionLinkHeader('Pagos')"
                :href="routes.adminHome"
              >
                <i class="ri-dashboard-line"></i>
                <span data-key="t-widgets">Home</span>
              </a>
            </li>
            <li class="nav-item">
              <a
                class="nav-link menu-link"
                href="#sidebarDashboards"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarDashboards"
              >
                <i class="las la-user-friends"></i>
                <span data-key="t-dashboards">Usuarios</span>
              </a>
              <div class="collapse menu-dropdown" id="sidebarDashboards">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <a
                      class="nav-link"
                      data-key="t-analytics"
                      v-if="validacionLinkHeader('Manejo_de_Usuarios')"
                      :href="routes.manejoUsuarios"
                    >
                      Manejo de Usuarios
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      v-if="validacionLinkHeader('ManejorolUsuarios')"
                      :href="routes.manejorolusuarios"
                      class="nav-link"
                      data-key="t-crm"
                    >
                      Manejo de Rol Usuarios
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      v-if="validacionLinkHeader('AdminRoles_Users')"
                      :href="routes.adminRoles"
                      class="nav-link"
                      data-key="t-ecommerce"
                    >
                      Administración de Roles para Usuarios
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      v-if="validacionLinkHeader('Promociones')"
                      :href="routes.promociones"
                      class="nav-link"
                      data-key="t-crypto"
                    >
                      Promociones (cupón)
                    </a>
                  </li>
                </ul>
              </div>
            </li>
            <!-- end Dashboard Menu -->
            <li class="nav-item">
              <a
                class="nav-link menu-link"
                href="#sidebarApps"
                data-bs-toggle="collapse"
                role="button"
                aria-expanded="false"
                aria-controls="sidebarApps"
              >
                <i class="las la-file-invoice-dollar"></i>
                <span data-key="t-apps">Facturas</span>
              </a>
              <div class="collapse menu-dropdown" id="sidebarApps">
                <ul class="nav nav-sm flex-column">
                  <li class="nav-item">
                    <a
                      v-if="validacionLinkHeader('Facturacion')"
                      :href="routes.facturacion"
                      class="nav-link"
                      data-key="t-calendar"
                    >
                      Facturación
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      v-if="validacionLinkHeader('HistorialFacturas')"
                      :href="routes.historial"
                      class="nav-link"
                      data-key="t-email"
                    >
                      Historial de Facturas
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      v-if="validacionLinkHeader('CierreFactura')"
                      :href="routes.cierrefactura"
                      class="nav-link"
                      data-key="t-email2"
                    >
                      Cierre de Factura
                    </a>
                  </li>
                  <li class="nav-item">
                    <a
                      v-if="validacionLinkHeader('Tarifas')"
                      :href="routes.tarifas"
                      class="nav-link"
                    >
                      <span data-key="t-file-manager">Tarifas</span></a
                    >
                  </li>
                </ul>
              </div>
            </li>

            <!-- end Dashboard Menu -->

            <li class="menu-title">
              <i class="ri-more-fill"></i>
              <span data-key="t-pages">Otras Opciones</span>
            </li>

            <li class="nav-item">
              <a
                class="nav-link menu-link"
                v-if="validacionLinkHeader('Paquetes_en_bodega')"
                :href="routes.paquetesBodega"
              >
                <i class="las la-warehouse"></i>
                <span data-key="t-widgets">Paquetes en bodega</span>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link menu-link"
                v-if="validacionLinkHeader('Pagos')"
                :href="routes.pagos"
              >
                <i class="las la-cash-register"></i>
                <span data-key="t-widgets">Pagos</span>
              </a>
            </li>

            <li class="nav-item">
              <a
                class="nav-link menu-link"
                v-if="validacionLinkHeader('homeEditImages')"
                :href="routes.homeEditImages"
              >
                <i class="las la-images"></i>
                <span data-key="t-widgets">Imagenes</span>
              </a>
            </li>
          </ul>
        </div>
        <!-- Sidebar -->
      </div>

      <div class="sidebar-background"></div>
    </div>
  </div>
</template>

<script>
import * as clientService from "../../services/clients.module.js";
import * as paqueteService from "../../services/paquetes.module";
import * as mensajitos from "../../helper/general.module";

import Swal from "sweetalert2";

export default {
  name: "AdminHeader",
  data() {
    return {
      host: location.host,
      permisosRol: [],
      searchTracking: "",
    };
  },
  props: ["cliente", "rol", "usuario", "routes"],
  methods: {
    getRol() {
      clientService.GetRolForHeaderAdmin("header").then((rol) => {
        this.permisosRol = rol[0].permisos;
      });
    },
    validacionLinkHeader(root) {
      if (this.usuario.role == 1) {
        return true;
      } else if (this.usuario.role >= 2) {
        if (this.permisosRol.indexOf(root) != "-1") {
          return true;
        }
        return false;
      }
    },
    SearchTracking() {
      mensajitos.toastr("Awanta, estamos buscando pa ve!", "info");
      var factura = "";
      paqueteService
        .getLocationPaqueteOFactura(this.searchTracking)
        .then((result) => {
          if (result.id !== undefined) {
            factura = result.numero_factura;
            Swal.fire({
              icon: "success",
              title: "Aqui t'a",
              text:
                `Factura #${result.numero_factura} del cliente ${result.cliente.nombre} ${result.cliente.apellido} con casillero ${result.cliente.tipo_plan}-${result.cliente.casillero}. Esta factura contiene ${result.items.length} paquete(s) con las siguientes ubicaciones: ` +
                result.items.map((i) => i.reference).join(", "),
              showDenyButton: true,
              confirmButtonText: "Ir a Cobrar",
              denyButtonText: `Ver Factura`,
              cancelButtonText: `Cerrar`,
              showCancelButton: true,
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                window.location.href = window.location.origin + "/Pagos";
              } else if (result.isDenied) {
                window.location.href =
                  window.location.origin +
                  "/HistorialFacturas?factura=" +
                  factura;
              }
            });
          } else {
            mensajitos.toastrlong("Xa no encontramos na'a mho!", "warning");
          }
        })

        .catch((error) => console.log(error));
    },
    verticalButton() {
      document
        .getElementById("vertical-hover")
        .addEventListener("click", function () {
          "sm-hover" ===
          document.documentElement.getAttribute("data-sidebar-size")
            ? document.documentElement.setAttribute(
                "data-sidebar-size",
                "sm-hover-active"
              )
            : (document.documentElement.getAttribute("data-sidebar-size"),
              document.documentElement.setAttribute(
                "data-sidebar-size",
                "sm-hover"
              ));
        });
    },
  },
  mounted() {
    this.getRol();
    this.verticalButton();
  },
};
</script>