<template>
  <v-app>
    <b-row class="text-dark">
      <b-col sm="12">
        <b-card
          class="elevation-2"
          :header="`${editMode ? 'Editar' : 'Crear'} Tarifa`"
          header-bg-variant="dark"
          header-class="text-white fw-bold h4"
        >
          <hr />
          <b-form
            @submit.prevent="
              `${editMode ? actualizarTarifa() : registrarTarifa()}`
            "
            @reset.prevent="onReset()"
          >
            <b-row>
              <b-col sm="12" md="4">
                <b-form-group label="Alegra ID:" label-for="alegra_id">
                  <b-form-input
                    id="alegra_id"
                    v-model="tarifa.alegra_id"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Nombre:" label-for="nombre">
                  <b-form-input
                    id="nombre"
                    v-model="tarifa.nombre"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Precio:" label-for="precio">
                  <b-form-input
                    id="precio"
                    v-model="tarifa.precio"
                    type="number"
                    step="0.01"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Tipo de plan:" label-for="tipo">
                  <b-form-select
                    id="tipo"
                    v-model="tarifa.tipo"
                    :options="planes"
                    required
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4" class="d-flex">
                <b-form-checkbox
                  class="my-auto"
                  id="is_vol"
                  v-model="tarifa.is_vol"
                  :value="1"
                  :unchecked-value="0"
                >
                  Volumétrico?
                </b-form-checkbox>
              </b-col>
              <b-col sm="6" class="mx-auto">
                <b-button-group class="w-100">
                  <b-button
                    type="submit"
                    :disabled="loading"
                    :variant="`${editMode ? 'warning' : 'danger'}`"
                  >
                    <span v-if="loading" class="text-white">
                      Cargando...<b-spinner
                        type="grow"
                        label="Spinning"
                        small
                      ></b-spinner>
                    </span>
                    <span v-else>
                      {{ editMode ? "Actualizar" : "Registrar" }}
                    </span>
                  </b-button>
                  <b-button type="reset" variant="secondary">Cancelar</b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col sm="12">
        <b-card
          class="elevation-2"
          header="Lista De Tarifas"
          header-bg-variant="dark"
          header-class="text-white fw-bold h4"
        >
          <hr />
          <v-data-table :headers="headers" :items="tarifas" class="elevation-2">
            <template v-slot:[`item.tipo`]="{ item }">
              {{ obtenerTipoPlan(item) }}
            </template>
            <template v-slot:[`item.is_vol`]="{ item }">
              <v-icon class="text-success" v-if="item.is_vol == 1"
                >mdi-check-circle</v-icon
              >
              <v-icon class="text-danger" v-else>mdi-block-helper</v-icon>
            </template>
            <template v-slot:[`item.opciones`]="{ item }">
              <v-btn
                title="Editar"
                elevation="1"
                color="warning"
                @click="asignarDatosEdit(item)"
                icon
                tile
                outlined
              >
                <v-icon>mdi-pencil-box</v-icon>
              </v-btn>
              <v-btn
                :title="item.estado == 0 ? 'Activar' : 'Desactivar'"
                elevation="1"
                @click="actualizarEstadoDeTarifa(item)"
                :color="item.estado == 0 ? 'success' : 'red'"
                icon
                outlined
                tile
              >
                <v-icon v-if="item.estado == 0"
                  >mdi-checkbox-marked-circle-outline</v-icon
                >
                <v-icon v-else>mdi-close-box</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </b-card>
      </b-col>
    </b-row>
  </v-app>
</template>

<script>
import * as TarifaService from "../../services/tarifa.module";
import * as GeneralHelper from "../../helper/general.module";
import Swal from "sweetalert2";

export default {
  name: "Admintarifas",
  data() {
    return {
      editMode: false,
      tarifa: {
        alegra_id: null,
        nombre: "",
        precio: null,
        tipo: null,
        is_vol: 0,
      },
      loading: false,
      tarifas: [],
      headers: [
        {
          text: "Alegra",
          value: "alegra_id",
        },
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Precio",
          value: "precio",
        },
        {
          text: "Tipo",
          value: "tipo",
        },
        {
          text: "Volumétrico",
          value: "is_vol",
          align: " text-center",
        },
        {
          text: "",
          value: "opciones",
        },
      ],
      planes: [],
      Toast: Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }),
    };
  },
  methods: {
    asignarDatosEdit(item) {
      this.editMode = true;
      this.tarifa = item;
    },
    obtenerTipoPlan(item) {
      let text = "";
      this.planes.forEach((element) => {
        if (item.tipo == element.value) {
          text = `${element.text} (${item.tipo})`;
        }
      });
      return text;
    },
    actualizarEstadoDeTarifa(item) {
      Swal.fire({
        title: "Esta seguro?",
        text: "se cambiara el estado de la tarifa!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar estado!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.Toast.fire({
            icon: "info",
            title: "Procesando, por favor espere!!",
          });
          TarifaService.ActualizarEstadoTarifa({
            id: item.id,
            estado: !item.estado,
          })
            .then((res) => {
              this.obtenerTarifas();
              GeneralHelper.ShowMessages(res);
            })
            .catch((res) => console.log(res));
        }
      });
    },
    actualizarTarifa() {
      this.loading = true;
      TarifaService.ActualizarTarifa(this.tarifa)
        .then((res) => {
          GeneralHelper.ShowMessages(res);
          this.obtenerTarifas();
          this.loading = false;
          if (res.status == 200) {
            this.onReset();
          }
        })
        .catch((res) => console.log(res));
    },
    registrarTarifa() {
      this.loading = true;
      TarifaService.RegistrarNuevaTarifa(this.tarifa)
        .then((res) => {
          GeneralHelper.ShowMessages(res);
          this.obtenerTarifas();
          this.loading = false;
          if (res.status == 200) {
            this.onReset();
          }
        })
        .catch((res) => console.log(res));
    },
    onReset() {
      this.editMode = false;
      this.tarifa = {
        alegra_id: null,
        nombre: "",
        precio: null,
        tipo: null,
        is_vol: 0,
      };
      this.loading = false;
    },
    async obtenerTarifas() {
      this.tarifas = await TarifaService.GetTarifas();
    },
    async obtenerPlanesParaTarifa() {
      this.planes = await TarifaService.GetPlanesListForTarifa();
    },
  },
  mounted() {
    this.obtenerPlanesParaTarifa();
    this.obtenerTarifas();
  },
};
</script>

<style>
</style>