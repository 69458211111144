<template>
  <div class="container-sm mt-5">
    <div class="row">
      <div class="col-md-6 col-sm-6">
        <div class="card">
          <div class="card-header">
            <h2 class="text-center">Calculadora de paquetes</h2>
          </div>
          <div class="card-body">
            <div class="pb-3">
              <label for="plan">Tipo de plan</label>
              <select
                id="plan"
                class="form-control form-control-lg"
                v-model="data.tarifa"
                @change="calculoEstimado()"
              >
                <option value="3.25">Basico (Sin Tarjeta)</option>
                <option value="2.50">Económico</option>
                <option value="2.25">Comercial</option>
              </select>
            </div>
            <div class="pb-3">
              <label for="alto">Peso</label>
              <input
                id="Peso"
                type="number"
                v-model="data.peso"
                value="0"
                min="0"
                class="form-control form-control-lg"
                @input="calculoEstimado()"
              />
              <code>(Libras)</code>
            </div>
            <div class="pb-3">
              <label for="alto">Alto</label>
              <input
                id="alto"
                type="number"
                v-model="data.alto"
                value="0"
                min="0"
                class="form-control form-control-lg"
                @input="calculoEstimado()"
              />
              <code>(Pulgadas)</code>
            </div>
            <div class="pb-3">
              <label for="ancho">Ancho</label>
              <input
                id="ancho"
                type="number"
                v-model="data.ancho"
                value="0"
                min="0"
                class="form-control form-control-lg"
                @input="calculoEstimado()"
              />
              <code>(Pulgadas)</code>
            </div>
            <div class="pb-3">
              <label for="largo">Largo</label>
              <input
                id="largo"
                type="number"
                v-model="data.largo"
                value="0"
                min="0"
                class="form-control form-control-lg"
                @input="calculoEstimado()"
              />
              <code>(Pulgadas)</code>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-sm-6">
        <div class="card">
          <div class="card-header">
            <h2 class="text-center">Costo aproximado</h2>
          </div>
          <div class="card-body">
            <table class="table" aria-label="Resultado de calculo">
              <tbody>
                <tr>
                  <th scope="row">Tipo de Plan:</th>
                  <td class="text-end">$ {{ data.tarifa }}</td>
                </tr>
                <tr>
                  <th scope="row">Peso:</th>
                  <td class="text-end">{{ data.peso }} libras.</td>
                </tr>
                <tr>
                  <th scope="row">Volumen:</th>
                  <td class="text-end">
                    {{
                      Math.ceil(
                        ((data.alto *
                          2.54 *
                          (data.ancho * 2.54) *
                          (data.largo * 2.54)) /
                          5000) *
                        2.2
                      )
                    }}
                    Volumen.
                  </td>
                </tr>
                <tr>
                  <th scope="row">Alto:</th>
                  <td class="text-end">{{ data.alto }} pulg.</td>
                </tr>
                <tr>
                  <th scope="row">Ancho:</th>
                  <td class="text-end">{{ data.ancho }} pulg.</td>
                </tr>
                <tr>
                  <th scope="row">Largo:</th>
                  <td class="text-end">{{ data.largo }} pulg.</td>
                </tr>
              </tbody>
            </table>
            <div class="col-md-12 text-end">
              <p><strong class="text-dark">Total:</strong> $ {{ total }}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import * as homeService from "../../services/auth.module.js";

export default {
  name: "Calculadora",
  data() {
    return {
      data: {
        tarifa: 3.25,
        peso: 0,
        largo: 0,
        ancho: 0,
        alto: 0,
      },
      total: 0,
    };
  },
  methods: {
    calculoEstimado() {
      if (
        (this.data.alto > 0 && this.data.ancho > 0 && this.data.largo > 0) ||
        this.data.peso > 0
      ) {
        homeService
          .Calculador(this.data)
          .then((res) => {
            if (res.status == 200) {
              this.total = res.data.result.toFixed(2);
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
  },
};
</script>