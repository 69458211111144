<template>
    <b-row>
        <b-col class="py-0">
            <h5>Suscripción de Noticias</h5>
            <b-form @submit="onSubmit" v-if="show">
                <b-input-group class="mt-3">
                    <b-form-input v-model="correo" placeholder="example@correo.com"></b-form-input>
                    <b-input-group-append>
                        <b-button variant="info" type="submit" disabled>Suscribirse</b-button>
                    </b-input-group-append>
                </b-input-group>
            </b-form>
        </b-col>
    </b-row>
</template>
<script>
export default {
    name: "Noticias",
    data() {
        return {
            show: true,
            correo: ""
        };
    },
    methods: {
        onSubmit(){
            //code here
        }
    }
};
</script>
