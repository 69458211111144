<template>
  <v-app>
    <v-card class="elevation-2">
      <v-card-title class="bg-dark text-white" primary-title>
        <v-col class="text-center p-0 font-weight-bold">
          Editar Imágenes (Home)
        </v-col>
      </v-card-title>
      <div class="row my-5">
        <h4 class="col-md-12 text-center">Imágenes Del Banner Superior</h4>
        <div class="col-md-6 col-sm-12 mx-auto">
          <form id="imageForm" @submit.prevent="uploadImages">
            <div class="row">
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="alt_name" class="font-weight-bold"
                    >Identificador</label
                  >
                  <input
                    type="text"
                    name="alt_name"
                    class="form-control"
                    id="alt_name"
                    value="banner_superior"
                    readonly
                  />
                </div>
              </div>
              <div class="col-md-6 col-sm-12">
                <div class="form-group">
                  <label for="file" class="font-weight-bold">Imagen</label>
                  <input
                    class="form-control"
                    type="file"
                    name="file"
                    id="file"
                    required
                  />
                </div>
              </div>
              <div class="col-md-12 text-center">
                <button
                  class="btn rojoShipex mx-auto"
                  form="imageForm"
                  :disabled="loading"
                >
                  Subir nuevo archivo
                  <v-progress-circular
                    v-if="loading"
                    indeterminate
                    color="white"
                  ></v-progress-circular>
                </button>
              </div>
            </div>
          </form>
        </div>
        <div class="col-md-10 mx-auto col-sm-12">
          <v-data-table
            :headers="headers"
            :items="images"
            :loading="cargando"
            loading-text="Cargando Datos... Por favor, Espere!!!"
            :items-per-page="5"
            class="elevation-1"
          >
            <template v-slot:[`item.preview`]="{ item }">
              <v-dialog width="fit-content">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn color="#af252b" icon dark v-bind="attrs" v-on="on">
                    <v-icon light> mdi-eye </v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-img
                    max-height="fit-content"
                    max-width="800"
                    :src="item.url_file"
                  ></v-img>
                </v-card>
              </v-dialog>
            </template>
            <template v-slot:[`item.opciones`]="{ item }">
              <v-btn
                color="secondary"
                @click="deleteImage(item)"
                icon
                dark
                title="Eliminar Imagen"
              >
                <v-icon light> mdi-delete </v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </div>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import * as imagesServices from "../../services/images.module";
import * as generalHelper from "../../helper/general.module";
import Swal from "sweetalert2";
export default {
  name: "Homeeditimages",
  data() {
    return {
      headers: [
        {
          text: "URL",
          value: "url_file",
        },
        {
          text: "Identificador",
          value: "alt_name",
        },
        {
          text: "Preview",
          value: "preview",
        },
        {
          text: "Opciones",
          value: "opciones",
        },
      ],
      images: [],
      formData: [],
      loading: false,
      cargando: false,
    };
  },
  methods: {
    getImages() {
      this.images = [];
      this.cargando = true;
      imagesServices.GetImages().then((res) => {
      this.cargando = false;
        this.images = res;
      });
    },
    uploadImages() {
      this.loading = true;
      this.formData = new FormData(document.getElementById("imageForm"));
      var name = this.formData.get("file").name;
      var currentDate = new Date();
      this.formData.append(
        "file_name",
        `${name.split(".")[0].replaceAll(" ", "_")}_${currentDate
          .toLocaleDateString("en-US")
          .replaceAll("/", "_")}.${name.split(".")[1]}`
      );
      imagesServices.UploadImages(this.formData).then((res) => {
        this.loading = false;
        document.getElementById("imageForm").reset();
        generalHelper.ShowMessages(res);
        this.getImages();
      });
    },
    deleteImage(item) {
      Swal.fire({
        showConfirmButton: false,
        icon: "info",
        toast: true,
        timer: 3000,
        position: "bottom-end",
        title: "Procesando Solicitud!!",
      });
      imagesServices.DeleteImage(item.id).then((res) => {
        this.getImages();
        generalHelper.ShowMessagesToastr(res);
      });
    },
  },
  mounted() {
    this.getImages();
  },
};
</script>

<style scoped>
.bg-title {
  background-color: #db253ab4;
  border-radius: 5%;
  font-weight: bold;
}
</style>