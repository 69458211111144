import { ApiService } from "./api.service";
import AlegraService from "./alegra.service";

export const RegistrarCliente = (credentials) => {
  // console.log(credentials);
  return new Promise(async resolve => {
    return ApiService.post("registro", credentials)
      .then((response) => {
        resolve(response);
        var cliente = response.data;
        RegistrarClienteAlegra(credentials, cliente);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const RegistrarClienteAlegraFromUserInfo = (client) => {

  var alegraUser = {
    name: client.full_name,
    nameObject: {
      firstName: client.full_name,
      lastName: client.full_name,
    },
    identificationObject: {
      number: client.cedula + ' / ' + client.casillero,
      dv: 0,
      kindOfPerson: "PERSON_ENTITY",
    },
    email: client.correo,
    mobile: client.telefono,
    phonePrimary: client.telefono,
    type: ['client'],
    term: 4
  }

  return new Promise(async resolve => {
    AlegraService.post("contacts", alegraUser)
      .then((response) => {
        var alegraClient = response.data;
        UpdateAlegraIdInSite(client.id, alegraClient.id);
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

const RegistrarClienteAlegra = (credentials, client) => {

  var alegraUser = {
    name: credentials.nombre + " " + credentials.apellido,
    nameObject: {
      firstName: credentials.nombre,
      lastName: credentials.apellido,
    },
    identificationObject: {
      number: credentials.cedula + ' / ' + client.code,
      dv: 0,
      kindOfPerson: "PERSON_ENTITY",
    },
    email: credentials.correo,
    mobile: credentials.telefono,
    phonePrimary: credentials.telefono,
    type: ['client'],
    term: 4
  }

  return new Promise(async resolve => {
    AlegraService.post("contacts", alegraUser)
      .then((response) => {
        var alegraClient = response.data;
        UpdateAlegraIdInSite(client.cliente_id, alegraClient.id);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

const UpdateAlegraIdInSite = (clientId, alegraClientId) => {
  var obj = {
    clienteId: clientId,
    alegraId: alegraClientId
  }
  return new Promise(async resolve => {
    ApiService.put("registro", obj)
      .then((data) => {
        resolve(data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const RecuperarContrasena = (data) => {
  // console.log(credentials);
  return new Promise(async resolve => {
    return ApiService.post("RecoverPass/mail", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response.data);
      });
  });
}

export const UpdatePassRecovery = (data) => {
  // console.log(credentials);
  return new Promise(async resolve => {
    return ApiService.post("RecoverPass/update", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response.data);
      });
  });
}

export const EnviarDataCompra = (data) => {
  // console.log(credentials);
  return new Promise(async resolve => {
    return ApiService.post("SendDataCompra", data)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response.data);
      });
  });
}

export const Calculador = (data) => {
  return new Promise(async resolve => {
    return ApiService.post("calculadora/calcular", data)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const Contactar = (contacto) => {
  return new Promise(async resolve => {
    return ApiService.post("contacto", contacto)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const ConvertirComercial = (clienteId) => {
  return new Promise(async resolve => {
    return ApiService.post("convertir/comercial/" + clienteId)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}
