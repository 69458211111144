import { ApiService } from "./api.service";

export const GetPromociones = () => {
  return new Promise(async resolve => {
    ApiService.get("promociones")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const GetVigenciasDePromociones = () => {
  return new Promise(async resolve => {
    ApiService.get("promociones/vigencias")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const RegistrarNuevaPromocion = (promo) => {
  return new Promise(async resolve => {
    ApiService.post("promocion", promo)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const ActualizarPromocion = (promo) => {
  return new Promise(async resolve => {
    ApiService.put("promocion", promo)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const ActualizarEstadoPromocion = (promo) => {
  return new Promise(async resolve => {
    ApiService.put("promocion/estado", promo)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const ValidCuponInsertByClient = (cupon) => {
  return new Promise(async resolve => {
    ApiService.post("/promocion/valid", cupon)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const CheckIfClientHaveActivePromo = () => {
  return new Promise(async resolve => {
    ApiService.get("/promocion/check")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const ExpireCuponForClient = () => {
  return new Promise(async resolve => {
    ApiService.get("/promocion/expire")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}