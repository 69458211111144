<template>
  <v-app>
    <div class="card shadow border-0">
      <h4 class="card-title text-white bg-dark h4 p-4">
        <em class="ri-wallet-2-line"></em> Facturas
        <a
          :href="link"
          target="_blank"
          class="btn btn-warning fw-bold text-dark float-right"
          data-bs-toggle="tooltip"
          id="facturas"
          title="Ver estado de cuenta en el sistema de cuentas."
          >Estado de cuenta <em class="ri-external-link-line"></em
        ></a>
      </h4>
      <div class="card-body">
        <v-data-table
          class="elevation-2"
          :headers="headersOpenFacturas"
          :items="openFacturas"
        >
          <template v-slot:[`item.accion`]="{ item }">
            <b-button
              small
              class="text-white"
              variant="warning"
              @click="verInfo(item)"
              v-b-modal.facturaModal
            >
              <i class="fas fa-eye"></i>
              Ver
            </b-button>
          </template>
        </v-data-table>
      </div>
    </div>
  </v-app>
</template>
<script>
import * as clientService from "../../services/client.module.js";

export default {
  name: "Facturas",
  props: ["alegraid"],
  data() {
    return {
      link: "",
      openFacturas: [],
      selectedFactura: {
        numero: 0,
        items: [],
        subtotal: 0.0,
        total: 0.0,
      },
      headersOpenFacturas: [
        { text: "Fecha", value: "date", align: "center" },
        { text: "Fecha de Vencimiento", value: "dueDate", align: "center" },
        { text: "Factura #", value: "numberTemplate.number", align: "center" },
        { text: "Total", value: "total", align: "center" },
        { text: "Acciones", value: "accion", align: "center" },
      ],
    };
  },
  methods: {
    getEstadoCuentaLink() {
      clientService.GetAlegraClientInfo(this.alegraid).then((alegraInfo) => {
        this.link = alegraInfo.statementLink;
      });
    },
    getFacturasAbiertas() {
      clientService.GetFacturasAbiertas(this.alegraid).then((facturas) => {
        this.openFacturas = facturas;
      });
    },
    verInfo(factura) {
      this.selectedFactura = factura;
      this.selectedFactura.numero = factura.numberTemplate.fullNumber;
    },
  },
  mounted() {
    this.getEstadoCuentaLink();
    this.getFacturasAbiertas();
    new bootstrap.Tooltip(document.getElementById("facturas"));
  },
};
</script>
<style>
.btn-yellow {
  background: #ffe001;
  color: black;
}
.bg-letters {
  display: none;
  position: absolute;
  height: auto;
  letter-spacing: 25px;
  writing-mode: vertical-rl;
  font-size: 25px;
  font-weight: bolder;
  color: #877777c2;
  z-index: 1;
  margin-left: 25%;
}
.rotate {
  transform: rotate(90deg);
}
.front {
  z-index: 2;
}
</style>