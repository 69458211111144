<template>
  <v-app class="elevation-2" style="background: transparent">
    <div class="row">
      <div class="col">
        <div class="h-100">
          <div class="row mb-3 pb-1">
            <div class="col-12">
              <div class="d-flex align-items-lg-center flex-lg-row flex-column">
                <div class="flex-grow-1">
                  <h3 class="fs-16 mb-1">Hola de nuevo, {{ user.nombre }}!</h3>
                  <p class="text-muted mb-0">
                    Aqui tienes algunas estadisticas de tus operaciones en el
                    mes de {{ getCurrentMonthInWords() }}.
                  </p>
                </div>
                <div class="mt-3 mt-lg-0">
                  <div class="form-group">
                    <label class="h5">Cambiar el mes actual</label>
                    <select
                      id="selectSucursal"
                      class="form-control"
                      name=""
                      v-model="currentMonth"
                      @change="getStats()"
                    >
                      Seleccione una sucursal
                      <option
                        :value="i"
                        v-for="(v, i) in monthNames"
                        v-bind:key="i"
                      >
                        {{ v }}
                      </option>
                    </select>
                    <small class="text-dark"
                      >Utilice esta opcion para filtrar por mes las estadisticas
                      base</small
                    >
                  </div>
                </div>
              </div>
              <!-- end card header -->
            </div>
            <!--end col-->
          </div>
          <!--end row-->
          <!-- contenido nuevo -->
          <div class="row">
            <div class="col-xl-3 col-md-6 col-12">
              <div
                class="stat-card-shipex_primary"
                @click="goToThis('/ManejoUsuarios')"
              >
                <div class="stat-card-left_content">
                  <p class="stat-card-title m-0">
                    TOTAL DE <span class="font-weight-bold">CLIENTES</span>
                  </p>
                  <small
                    class="counter-value fw-bold"
                    :data-target="stats.todos"
                    >0</small
                  >
                </div>
                <div class="stat-card-right_content">
                  <span
                    class="stat-card-icon avatar-title rounded fs-3"
                    style="background-color: #fee060 !important"
                  >
                    <i class="bx bx-user-circle" style="color: #f4911f"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-12">
              <div
                class="stat-card-shipex_secondary"
                @click="goToThis('/HistorialFacturas')"
              >
                <div class="stat-card-left_content">
                  <p class="stat-card-title m-0">
                    TOTAL DE <span class="font-weight-bold">FACTURAS</span>
                  </p>
                  <small
                    class="fw-bold counter-value"
                    :data-target="stats.facturas"
                    >0</small
                  >
                </div>
                <div class="stat-card-right_content">
                  <span
                    class="stat-card-icon avatar-title rounded fs-3"
                    style="background-color: rgb(130 215 248) !important"
                  >
                    <i class="bx bx-receipt" style="color: rgb(81 177 227)"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-12">
              <div class="stat-card-shipex_primary">
                <div class="stat-card-left_content">
                  <p class="stat-card-title m-0">
                    TOTAL DE <span class="font-weight-bold">PAQUETES</span>
                  </p>
                  <small
                    class="fw-bold counter-value"
                    :data-target="stats.paquetes"
                    >0</small
                  >
                </div>
                <div class="stat-card-right_content">
                  <span
                    class="stat-card-icon avatar-title rounded fs-3"
                    style="background-color: rgb(254 217 185) !important"
                  >
                    <i
                      class="bx bx-package"
                      style="color: rgb(175 143 105)"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-12">
              <div
                class="stat-card-shipex_secondary"
                @click="goToThis('/PaquetesBodega')"
              >
                <div class="stat-card-left_content">
                  <p class="stat-card-title m-0">
                    TOTAL DE <span class="font-weight-bold">LIBRAS</span>
                  </p>
                  <small
                    class="fw-bold counter-value"
                    :data-target="stats.total_libras"
                    >0</small
                  >
                </div>
                <div class="stat-card-right_content">
                  <span
                    class="stat-card-icon avatar-title rounded fs-3"
                    style="background-color: rgb(128 251 166) !important"
                  >
                    <i
                      class="bx bx-user-circle"
                      style="color: rgb(75 197 117)"
                    ></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="col-xl-3 col-md-6 col-12">
              <div
                class="stat-card-shipex_primary"
                @click="goToThis('/PaquetesBodega')"
              >
                <div class="stat-card-left_content">
                  <p class="stat-card-title m-0">
                    TOTAL DE <span class="font-weight-bold">COSTO AEREO</span>
                  </p>
                  <small
                    class="fw-bold counter-value"
                    :data-target="stats.total_libras * 1.9"
                    >0</small
                  >$
                </div>
                <div class="stat-card-right_content">
                  <span
                    class="stat-card-icon avatar-title rounded fs-3"
                    style="background-color: rgb(128 251 166) !important"
                  >
                    <i class="bx bx-money" style="color: rgb(75 197 117)"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-md-8 col-12">
              <div class="card rounded-3" style="padding: 1rem">
                <div class="card-body rounded-3 border">
                  <h4 class="fw-bold">ESTADÍSTICAS</h4>
                  <div class="row">
                    <div class="col-12">
                      <table
                        class="table table-bordered table-nowrap text-center"
                      >
                        <thead>
                          <tr>
                            <th scope="col" class="hover-stats-animation">
                              {{ statsAdvance.clientesMonthNow }}
                              <span class="flotating-title"
                                >Clientes registrados en el mes</span
                              >
                            </th>
                            <th scope="col" class="hover-stats-animation">
                              {{ statsAdvance.ventasMonthNow }}
                              <span class="flotating-title"
                                >Total de ventas en el mes corriente</span
                              >
                            </th>
                            <th scope="col" class="hover-stats-animation">
                              {{ statsAdvance.librasMonthNow }}
                              <span class="flotating-title"
                                >Total de libras en el mes corriente</span
                              >
                            </th>
                          </tr>
                        </thead>
                      </table>
                    </div>
                  </div>
                  <div
                    id="chartEstadisticas"
                    class="apex-charts fw-bold"
                    dir="ltr"
                  ></div>
                </div>
              </div>
            </div>
            <div class="col-md-4 col-12">
              <div class="card rounded-3" style="padding: 1rem">
                <div class="card-body rounded-3 border p-2">
                  <h4 class="fw-bold">SUCURSALES</h4>
                  <div
                    id="world-map-markers"
                    style="height: 220px"
                    dir="ltr"
                  ></div>
                  <!-- Sucursales -->
                  <div
                    class="mb-2"
                    style="display: flex; flex-direction: column"
                  >
                    <div
                      class="my-1"
                      style="display: flex; flex-direction: row"
                    >
                      <i
                        class="bx bx-radio-circle-marked"
                        style="
                          color: #f89019;
                          font-size: 26px;
                          text-shadow: 0 0 5px #f89019;
                        "
                      ></i>
                      <h6 class="fw-bold m-0 my-auto">
                        Colón | Plaza La Rotonda 4 alto
                      </h6>
                    </div>
                    <div
                      class="my-1"
                      style="display: flex; flex-direction: row"
                    >
                      <i
                        class="bx bx-radio-circle-marked"
                        :style="`
                          color: #01813f;
                          font-size: 26px;
                          text-shadow: 0 0 5px #01813f;
                        `"
                      ></i>
                      <h6 class="fw-bold m-0 my-auto">
                        24 De Diciembre | La Doña
                      </h6>
                    </div>
                    <div
                      class="my-1"
                      style="display: flex; flex-direction: row"
                    >
                      <i
                        class="bx bx-radio-circle-marked"
                        :style="`
                          color: #038edc;
                          font-size: 26px;
                          text-shadow: 0 0 5px #038edc;
                        `"
                      ></i>
                      <h6 class="fw-bold m-0 my-auto">
                        Arraiján | Town Center
                      </h6>
                    </div>
                  </div>
                  <div class="row" v-if="statsAdvance.totalPorSucursal">
                    <div class="col-12">
                      <h6 class="fw-bold text-muted m-0 text-center mb-2">
                        TOTAL DE VENTAS
                      </h6>
                      <div class="d-flex my-1" style="flex-wrap: wrap">
                        <i
                          class="bx bx-radio-circle-marked"
                          :style="`
                            color: #f89019;
                            font-size: 26px;
                            text-shadow: 0 0 5px #f89019;
                          `"
                        ></i>
                        <h6 class="fw-bold m-0 my-auto">
                          Colón |
                          <small
                            class="counter-value fw-bold text-black"
                            :data-target="
                              statsAdvance.totalPorSucursal[1].total_ventas
                            "
                          >
                            0</small
                          >
                        </h6>
                        <button
                          class="ml-2 btn btn-sucursal-warning btn-sm fw-bold"
                          @click="
                            generateExcel(statsAdvance.totalPorSucursal[1])
                          "
                        >
                          REPORTE
                        </button>
                      </div>
                      <div class="d-flex my-1" style="flex-wrap: wrap">
                        <i
                          class="bx bx-radio-circle-marked"
                          :style="`
                            color: #01813f;
                            font-size: 26px;
                            text-shadow: 0 0 5px #01813f;
                          `"
                        ></i>
                        <h6 class="fw-bold m-0 my-auto">
                          24 De Diciembre |
                          <small
                            class="counter-value fw-bold text-black"
                            :data-target="
                              statsAdvance.totalPorSucursal[0].total_ventas
                            "
                          >
                            0</small
                          >$
                        </h6>
                        <button
                          class="ml-2 btn btn-sucursal-success btn-sm fw-bold"
                          @click="
                            generateExcel(statsAdvance.totalPorSucursal[0])
                          "
                        >
                          REPORTE</button
                        >small
                      </div>
                      <div class="d-flex my-1" style="flex-wrap: wrap">
                        <i
                          class="bx bx-radio-circle-marked"
                          :style="`
                            color: #038edc;
                            font-size: 26px;
                            text-shadow: 0 0 5px #038edc;
                          `"
                        ></i>
                        <h6 class="fw-bold m-0 my-auto">
                          Arraiján |
                          <small
                            class="counter-value fw-bold text-black"
                            :data-target="
                              statsAdvance.totalPorSucursal[2].total_ventas
                            "
                          >
                            0</small
                          >
                        </h6>
                        <button
                          class="ml-2 btn btn-sucursal-primary btn-sm fw-bold"
                          @click="
                            generateExcel(statsAdvance.totalPorSucursal[2])
                          "
                        >
                          REPORTE
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 col-md-4">
              <div class="card rounded-3" style="padding: 1rem">
                <div class="card-body rounded-3 border">
                  <h4 class="fw-bold">DISTRIBUCIÓN DE CLIENTES</h4>
                  <div class="row">
                    <div class="col-12">
                      <div id="chart-pie" class="e-charts"></div>
                      <hr class="border-dark" />
                      <h6 class="fw-bold">
                        Clientes Sin Facturas |
                        <small
                          >{{ stats.sin_alegra }} de {{ stats.todos }}</small
                        >
                      </h6>
                      <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                          <div class="avatar-xs">
                            <div
                              :class="`avatar-title rounded-circle text-white fs-16 bg-${definirPorcentaje(
                                (stats.sin_alegra / stats.todos) * 100
                              )}`"
                            >
                              <i class="mdi mdi-account-alert-outline"></i>
                            </div>
                          </div>
                        </div>
                        <div class="flex-grow-1">
                          <div
                            class="progress animated-progress custom-progress progress-label"
                            style="overflow: visible"
                          >
                            <div
                              :class="`progress-bar bg-${definirPorcentaje(
                                (stats.sin_alegra / stats.todos) * 100
                              )}`"
                              role="progressbar"
                              :style="`width: ${
                                (stats.sin_alegra / stats.todos) * 100
                              }%`"
                            >
                              <div
                                :class="`label bg-${definirPorcentaje(
                                  (stats.sin_alegra / stats.todos) * 100
                                )}`"
                              >
                                {{
                                  (
                                    (stats.sin_alegra / stats.todos) *
                                    100
                                  ).toFixed(0)
                                }}%
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="helperStatus text-dark fw-bold mt-2">
                        <div class="d-flex align-items-center">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar-xxxs">
                              <div
                                class="avatar-title rounded text-white fs-3 bg-success"
                              ></div>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <p class="m-0 fs-13px">Todo Bien!</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar-xxxs">
                              <div
                                class="avatar-title rounded text-white fs-3 bg-warning"
                              ></div>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <p class="m-0 fs-13px">Revisar!</p>
                          </div>
                        </div>
                        <div class="d-flex align-items-center">
                          <div class="flex-shrink-0 me-3">
                            <div class="avatar-xxxs">
                              <div
                                class="avatar-title rounded text-white fs-3 bg-danger"
                              ></div>
                            </div>
                          </div>
                          <div class="flex-grow-1">
                            <p class="m-0 fs-13px">Urgente!</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-12 col-md-8">
              <div class="card rounded-3" style="padding: 1rem">
                <div class="card-body rounded-3 border">
                  <div class="d-flex mb-2">
                    <div class="left-icon">
                      <span class="avatar-title rounded fs-3"
                        ><i class="bx bxs-server"></i
                      ></span>
                    </div>
                    <div class="right-content">
                      <h4 class="m-0">
                        <span class="fw-bold">Top</span> De Los Mejores Clientes
                      </h4>
                      <small class="text-muted"
                        >Se mostraran los 10 mejores clientes con sus datos
                        ordenados por cantidad de facturas.</small
                      >
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-12">
                      <!-- Bordered Tables -->
                      <table class="table table-nowrap">
                        <thead>
                          <tr
                            class="text-center"
                            style="background-color: #fef7c3"
                          >
                            <th scope="col" class="border-right">
                              NOMBRE Y APELLIDO
                            </th>
                            <th scope="col" class="border-right">FACTURAS</th>
                            <th scope="col" class="border-right">VALOR</th>
                            <th scope="col" class="border-right">LIBRAS</th>
                            <th scope="col" class="border-left">PORCENTAJE</th>
                          </tr>
                        </thead>
                        <tbody class="text-center">
                          <tr
                            v-for="(
                              topClientes, key
                            ) in statsAdvance.topClientes"
                            :key="topClientes.id"
                            :class="`table-custom-${number_test(
                              topClientes.id / 2
                            )}`"
                          >
                            <td class="border-right border-top-0">
                              <div class="fw-bold d-flex">
                                <span
                                  class="avatar-title rounded fs-3"
                                  :style="`height: 30px !important;
                                    margin-top: auto;
                                    width: 30px !important;
                                    color:${colorsForStats[key]} ;
                                    background-color:${colorsForStats[key]}8a`"
                                  ><i class="bx bx-user-circle"></i
                                ></span>
                                <span class="ml-2">
                                  {{
                                    topClientes.cliente.nombre +
                                    " " +
                                    topClientes.cliente.apellido
                                  }}
                                </span>
                              </div>
                            </td>
                            <td class="border-right border-top-0">
                              <div class="">
                                {{ topClientes.factura }}
                              </div>
                            </td>
                            <td class="border-right border-top-0">
                              <div class="">
                                ${{ topClientes.total_facturado.toFixed(2) }}
                              </div>
                            </td>
                            <td class="border-right border-top-0">
                              <div class="">
                                {{ topClientes.libras }}
                              </div>
                            </td>
                            <td class="border-left border-top-0">
                              <div class="">
                                {{
                                  (
                                    (topClientes.total_facturado * 100) /
                                    statsAdvance.topClientes.reduce(
                                      (sum, e) => e.total_facturado + sum,
                                      0
                                    )
                                  ).toFixed(2)
                                }}%
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="col-12">
                      <div id="donut-cliente" class="apex-charts" dir="ltr">
                        <span class="total-value-charts"></span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- /contenido nuevo -->
        </div>
        <!-- end .h-100-->
      </div>
    </div>
  </v-app>
</template>
<script>
import Swal from "sweetalert2";
import * as statService from "../../services/stats.module";
var XLSX = require("xlsx");

export default {
  name: "Homestats",
  props: ["user"],
  data() {
    return {
      colorsForStats: ["#34d386", "#21aae2", "#fdaf37", "#d9df28", "#2f3097"],
      stats: {},
      statsAdvance: {
        topClientes: [],
      },
      headers: [
        {
          text: "Alegra",
          value: "alegra_id",
        },
        {
          text: "Nombre",
          value: "cliente.nombre",
        },
        {
          text: "Apellido",
          value: "cliente.apellido",
        },
        {
          text: "Plan",
          value: "cliente.tipo_plan",
        },
        {
          text: "Casillero",
          value: "cliente.casillero",
        },
        {
          text: "Facturas",
          value: "factura",
        },
        {
          text: "Libras / Volumen",
          value: "libras",
        },
      ],
      currentMonth: new Date().getMonth(),
      monthNames: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
    };
  },
  methods: {
    definirPorcentaje: (percen) => {
      if (percen.toFixed(0) < 25) {
        return "success";
      } else if (percen.toFixed(0) > 25 && percen.toFixed(0) < 50) {
        return "warning";
      } else {
        return "danger";
      }
    },
    goToThis: (url) => {
      location.href = url;
    },
    topClientes() {
      var datas = [];
      var labels = [];
      this.statsAdvance.topClientes.forEach((element) => {
        datas.push(element.total_facturado);
        labels.push(element.cliente.nombre + " " + element.cliente.apellido);
      });
      var options = {
        chart: {
          height: 350,
          type: "donut",
        },
        series: datas,
        labels: labels,
        stroke: {
          width: 0,
        },
        colors: this.colorsForStats,
        plotOptions: {
          pie: {
            expandOnClick: false,
            donut: {
              size: "80%",
              labels: {
                show: true,
                total: {
                  showAlways: true,
                  show: true,
                  label: "Total",
                  fontSize: "22px",
                  fontWeight: 600,
                  formatter: function (w) {
                    $(".total-value-charts").html(
                      "$" +
                        w.globals.seriesTotals
                          .reduce((a, b) => {
                            return a + b;
                          }, 0)
                          .toFixed(2)
                    );
                    return "";
                  },
                },
              },
            },
          },
        },
        legend: {
          show: false,
        },
        dataLabels: {
          enabled: false,
        },
      };

      var chart = new ApexCharts(
        document.getElementById("donut-cliente"),
        options
      );
      chart.render();
    },
    chartPlanes() {
      var chartDom = document.getElementById("chart-pie");
      var myChart = echarts.init(chartDom);
      var option;

      option = {
        color: this.colorsForStats,
        tooltip: {
          trigger: "item",
        },
        legend: {
          orient: "horizontal",
          left: "top",
          textStyle: {
            //The style of the legend text
            color: "#858d98",
          },
        },
        series: [
          {
            name: "Clientes Por Planes",
            type: "pie",
            radius: "50%",
            data: [
              { value: this.stats.economico, name: "Económico" },
              {
                value: this.stats.economico_peso,
                name: "Económico Sin Volumen",
              },
              { value: this.stats.sin_tarjeta, name: "Sin tarjeta" },
              {
                value: this.stats.sin_tarjeta_peso,
                name: "Sin tarjeta Sin Volumen",
              },
              { value: this.stats.comercial, name: "Comercial" },
            ],
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: "rgba(0, 0, 0, 0.5)",
              },
            },
          },
        ],
        textStyle: {
          fontFamily: "Poppins, sans-serif",
        },
      };

      option && myChart.setOption(option);
    },
    panamaMap() {
      var worldemapmarkers = new jsVectorMap({
        map: "Panama",
        selector: "#world-map-markers",
        zoomOnScroll: false,
        zoomButtons: false,
        selectedMarkers: [0, 2],
        regionStyle: {
          initial: {
            stroke: "#cccccc",
            strokeWidth: 0.25,
            fill: "#9599ad",
            fillOpacity: 1,
          },
        },
        selectedMarkers: [],
        markers: [
          {
            name: "Colón | Plaza La Rotonda 4 alto",
            coords: [430, 50],
            style: { fill: "#f89019" },
          },
          {
            name: "24 De Diciembre | La Doña",
            coords: [500, 85],
            style: { fill: "#01813f" },
          },
          {
            name: "Arraiján | Town Center",
            coords: [445, 100],
            style: { fill: "#038edc" },
          },
        ],
        labels: {
          markers: {
            render: function (marker) {
              return "";
            },
          },
        },
      });
    },
    chartEstadisticas() {
      var options = {
        series: [
          {
            name: "CLIENTES REGISTRADOS POR MES",
            type: "column",
            data: this.statsAdvance.staticsMensuales.clientes,
          },
          {
            name: "TOTAL DE VENTAS MENSUALES",
            type: "line",
            data: this.statsAdvance.staticsMensuales.ventas,
          },
          {
            name: "TOTAL DE PAQUETES MENSUAL",
            type: "area",
            data: this.statsAdvance.staticsMensuales.paquetes,
          },
        ],
        chart: {
          height: 350,
          type: "line",
          stacked: false,
        },
        stroke: {
          width: [0, 5, 5],
          colors: ["#64c7ea", "#9ce77a", "#e5af50"],
          curve: "straight",
          dashArray: [0, 15, 0],
        },
        plotOptions: {
          bar: {
            columnWidth: "20%",
            borderRadius: 4,
          },
        },

        fill: {
          opacity: [0.85, 1, 0.25],
          colors: ["#64c7ea", "#9ce77a", "#e5af50"],
          type: ["solid", "solid", "gradient"],
          gradient: {
            inverseColors: false,
            shade: "light",
            type: "vertical",
            opacityFrom: 1,
            opacityTo: 0,
            stops: [0, 100],
          },
        },
        labels: [
          "ENERO",
          "FEBRERO",
          "MARZO",
          "ABRIL",
          "MAYO",
          "JUNIO",
          "JULIO",
          "AGOSTO",
          "SEPTIEMBRE",
          "OCTUBRE",
          "NOVIEMBRE",
          "DICIEMBRE",
        ],
        markers: {
          size: 0,
        },
        xaxis: {
          type: "category",
        },
        yaxis: {
          title: {
            text: "",
          },
          min: 0,
        },
        tooltip: {
          shared: true,
          intersect: false,
          y: {
            formatter: function (y) {
              if (typeof y !== "undefined") {
                return y.toFixed(0) + " points";
              }
              return y;
            },
          },
        },
      };

      var chart = new ApexCharts(
        document.getElementById("chartEstadisticas"),
        options
      );
      chart.render();
    },
    getStats() {
      this.resetCounter();
      statService
        .GetAdminStats(this.currentMonth + 1)
        .then((stats) => {
          this.stats = stats;
          this.chartPlanes();
        })
        .catch((error) => console.log(error));

      statService
        .GetAdminStatsAdvance(this.currentMonth + 1)
        .then((statsAdvance) => {
          this.statsAdvance = statsAdvance;
          this.panamaMap();
          this.topClientes();
          this.chartEstadisticas();

          setTimeout(() => {
            this.counter();
          }, 2000);
        })
        .catch((error) => console.log(error));
    },
    counter() {
      let e = document.querySelectorAll(".counter-value");
      e.forEach((element) => {
        let a = setInterval(() => {
          let data = element.getAttribute("data-target");
          if (parseInt(data) != parseInt(element.innerText)) {
            element.innerText = parseInt(element.innerText) + 1;
          } else {
            element.innerText = `${element.innerText}${
              data.indexOf(".") > 0 ? "." + data.split(".")[1] : ""
            }`;
            clearInterval(a);
          }
        }, 1);
      });
    },
    resetCounter() {
      let e = document.querySelectorAll(".counter-value");
      e.forEach((element) => {
        element.innerText = 0;
        element.setAttribute("data-target", "0");
      });
    },
    number_test(n) {
      var result = n - Math.floor(n) !== 0;
      if (result) return "success";
      else return "danger";
    },
    generateExcel(sucursal) {
      var fixItems = sucursal.facturas;
      const facturas = XLSX.utils.json_to_sheet(fixItems);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, facturas, "facturas");
      XLSX.writeFile(wb, `Invoices_from_${sucursal.nombre}.xlsx`);

      Swal.fire({
        icon: "success",
        title: "Reporte generado!",
        toast: true,
        showConfirmButton: false,
        timer: 3000,
        position: "bottom-end",
      });
    },
    getCurrentMonthInWords() {
      return this.monthNames[this.currentMonth];
    },
  },
  mounted() {
    this.getStats();
  },
};
</script>

<style>
.left-icon > span {
  background-color: #7fe1f9;
  color: #0097ff;
  height: 30px !important;
  margin-top: auto;
  width: 30px !important;
}
.right-content {
  color: black;
  margin-left: 0.5rem;
}
.progress-label .progress-bar .label:after {
  content: "";
  position: absolute;
  border: 4px solid transparent;
  border-top-color: #00000000;
}
.table-custom-success > td {
  background-color: rgb(213, 255, 213);
}
.table-custom-danger > td {
  background-color: rgb(255, 213, 213);
}
.hover-stats-animation {
  transition: all 0.4s;
}
.hover-stats-animation:hover {
  background-color: #ff363c45;
  border-color: #c2272c00;
  box-shadow: 0 0 8px 0px #c2272cb0;
  text-shadow: -2px 1px 2px #ffffff;
}

.flotating-title {
  visibility: hidden;
  position: absolute;
  right: 32%;
  bottom: 98%;
  z-index: 30;
  background-color: black;
  padding: 4px;
  border-radius: 11px;
  color: white;
  text-shadow: none;
  opacity: 0;
  transition: all 0.4s;
}
.hover-stats-animation:hover > .flotating-title {
  visibility: visible;
  opacity: 0.6;
}

.btn-sucursal-primary {
  color: #fff;
  background-color: #038edc;
  border-color: #038edc;
}
.btn-sucursal-primary:hover {
  color: #fff;
  background-color: #00a2ff;
  border-color: #00a2ff;
}
.btn-sucursal-success {
  color: #fff;
  background-color: #01813f;
  border-color: #01813f;
}
.btn-sucursal-success:hover {
  color: #fff;
  background-color: #02ac54;
  border-color: #02ac54;
}
.btn-sucursal-warning {
  color: #fff;
  background-color: #f89019;
  border-color: #f89019;
}
.btn-sucursal-warning:hover {
  color: #fff;
  background-color: #c27114;
  border-color: #c27114;
}
.stat-card-shipex_secondary,
.stat-card-shipex_primary {
  display: flex;
  min-height: 65px;
  padding: 15px;
  max-width: 305px;
  border-radius: 20px;
  justify-content: space-between;
  transition: all 0.4s;
  cursor: pointer;
}
.stat-card-shipex_primary {
  background: #c2272d;
}
.stat-card-shipex_secondary {
  color: black !important;
  background: #cccccc;
}
.stat-card-shipex_primary:hover,
.stat-card-shipex_secondary:hover {
  transform: translateY(calc(-1.5rem / 5));
  -webkit-box-shadow: 0 5px 10px rgb(30 32 37 / 12%);
  box-shadow: 0 5px 10px rgb(30 32 37 / 43%);
}
.stat-card-icon {
  height: 35px !important;
  margin-top: auto;
  width: 35px !important;
}
.stat-card-right_content {
  display: flex;
}
.total-value-charts {
  position: absolute;
  right: 44%;
  top: 50%;
  z-index: 2;
  font-weight: 600;
  font-size: 20px;
  padding-right: 0.6em;
  padding-left: 0.6em;
  border-radius: 10rem;
  background-color: #a5ffd5;
  color: #45a575;
}
.avatar-xxxs {
  height: 1rem;
  width: 1rem;
}
.fs-14px {
  font-size: 14px;
}
.helperStatus {
  display: flex;
  justify-content: space-around;
  padding-top: 8px;
}
</style>