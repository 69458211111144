import { ApiService } from "./api.service";

export const GetAllClientesInfo = (data) => {
  return new Promise(async resolve => {
    ApiService.get("getAllClients/" + data)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}
export const GetInfoCliente = (data) => {
  return new Promise(async resolve => {
    ApiService.get("getCliente/" + data)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}

export const GetRolForHeaderAdmin = (data) => {
  return new Promise(async resolve => {
    ApiService.get("getClientsRol/" + data)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}


export const GetRolCliente = () => {
  return new Promise(async resolve => {
    ApiService.get("getClientsRol/0")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}

export const GuardarRolUsers = (rolInfo) => {
  // console.log(rolInfo);
  return new Promise(async resolve => {
    return ApiService.post("GuardarRolUsers", rolInfo)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const DeleteRolUsers = (idRol) => {
  // console.log(rolInfo);
  return new Promise(async resolve => {
    return ApiService.delete("DeleteRolUsers/" + idRol)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const UpdateRolPermisoUser = (newData) => {
  return new Promise(async resolve => {
    ApiService.put("UpdateRolPermisoUser", newData)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}

export const UpdateUserPass = (newData) => {
  return new Promise(async resolve => {
    ApiService.put("actualizarPass", newData)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}

export const UpdateUserAlegraId = (newAlegraId) => {
  return new Promise(async resolve => {
    ApiService.put("actualizarAlegraId", newAlegraId)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}

export const UpdateUserData = (newData) => {
  return new Promise(async resolve => {
    ApiService.put("actualizarUserData", newData)
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}

export const UpdateRolUser = (newData) => {
  return new Promise(async resolve => {
    ApiService.put("actualizarRolUser", newData)
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        context.commit(SET_ERROR, response.data.errors);
      });
  });
}