<template>
  <v-app>
    <v-card class="elevation-2">
      <v-card-title class="bg-dark text-white" primary-title>
        <v-col class="text-center p-0 font-weight-bold">
          Listado de Paquetes en bodega
        </v-col>
      </v-card-title>
      <div class="p-3">
        <b-row>
          <b-col>
            <b-form-group
              label="# de Tracking o Factura"
              label-class="font-weight-bold"
              class="mt-3"
              style="max-width: 356px !important"
            >
              <b-input-group>
                <b-form-input
                  v-model="searchTrack"
                  @change="btnactive = searchTrack == ''"
                ></b-form-input>
                <b-input-group-append>
                  <b-button
                    @click="getpackageInfo"
                    variant="outline-danger"
                    :disabled="btnactive"
                    >Buscar <i class="fas fa-search"></i>
                    <b-spinner
                      small
                      v-if="spnactive"
                      label="Spinning"
                    ></b-spinner
                  ></b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>
        <hr />
        <b-row>
          <b-col class="d-flex justify-content-center">
            <transition name="fade">
              <v-card
                v-if="factura.id != undefined"
                style="max-width: 1100px !important; width: 1100px"
              >
                <v-card-title class="bg-danger text-white" primary-title>
                  <v-col class="text-center p-0 font-weight-bold">
                    Información de la Factura en base a su búsqueda
                    <button
                      v-if="factura.inBodega == 'Si'"
                      class="btn btn-warning font-weight-bold"
                      @click="verifySalida"
                    >
                      Dar Salida
                    </button>
                  </v-col>
                </v-card-title>
                <b-row class="m-0">
                  <!-- datos de factura -->
                  <b-col>
                    <b-input-group
                      prepend-html="<span class='font-weight-bold'>Fecha de Facturación:</span>"
                      class="mb-2"
                    >
                      <b-form-input
                        v-model="factura.fecha"
                        disabled
                      ></b-form-input>
                    </b-input-group>

                    <b-input-group
                      class="mb-2"
                      prepend-html="<span class='font-weight-bold'># de Factura:</span>"
                    >
                      <b-form-input
                        v-model="factura.numero_factura"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <!--Datos Paquetes -->
                    <b-input-group
                      class="mb-2"
                      prepend-html="<span class='font-weight-bold'>En Bodega:</span>"
                    >
                      <b-form-input
                        v-model="factura.inBodega"
                        disabled
                      ></b-form-input>
                    </b-input-group>

                    <b-input-group
                      class="mb-2"
                      prepend-html="<span class='font-weight-bold'>Total de Factura:</span>"
                    >
                      <b-form-input
                        v-model="factura.total"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                </b-row>
                <hr class="m-0" />
                <h4 class="m-2">Listado de Paquetes</h4>
                <b-row
                  v-for="paquete in factura.items"
                  :key="paquete.reference"
                  class="m-4 border border-dark rounded"
                >
                  <b-col>
                    <b-input-group
                      class="mb-2"
                      prepend-html="<span class='font-weight-bold'># de Tracking:</span>"
                    >
                      <b-form-input
                        v-model="paquete.reference"
                        disabled
                      ></b-form-input>
                    </b-input-group>
                  </b-col>
                  <b-col>
                    <b-input-group
                      class="mb-2"
                      prepend-html="<span class='font-weight-bold'>Descripción:</span>"
                    >
                      <b-form-textarea
                        id="textarea"
                        v-model="paquete.description"
                        disabled
                        rows="2"
                        max-rows="6"
                      ></b-form-textarea>
                    </b-input-group>
                  </b-col>
                </b-row>
              </v-card>
            </transition>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
             <v-data-table
            :headers="headers"
            :items="paquetes"
            class="border elevation-1"
            item-key="tracking"
          >
            <template v-slot:[`item.salida`]="{ item }">
              <v-icon
                v-if="item.salida == 1"
                medium
                class="mr-2 text-danger"
                v-b-tooltip.hover
                title="Paquete en bodega"
              >
                mdi-block-helper
              </v-icon>
              <v-icon
                v-if="item.salida == 0"
                medium
                class="mr-2 text-success"
                v-b-tooltip.hover
                title="Paquete entregado"
              >
                mdi-check
              </v-icon>
            </template>
          </v-data-table>
          </b-col>
        </b-row>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import moment from "moment";
import * as paquetesService from "../../services/paquetes.module.js";
import Swal from "sweetalert2";
export default {
  name: "PaquetesBodega",
  data() {
    return {
      searchTrack: "",
      btnactive: true,
      spnactive: false,
      factura: {},
      headers:[
        { text: "Cliente", value: "cliente", align: "center" },
        { text: "Factura", value: "factura", align: "center" },
        { text: "Tracking y Ubicacion", value: "tracking", align: "center" },
        { text: "Pagada", value: "pagada", align: "center" },
        { text: "Paquete entregado?", value: "salida", align: "center" },
        { text: "Fecha de Salida", value: "fecha_salida", align: "center" },
      ],
      paquetes: [],
    };
  },
  methods: {
    getpackageInfo() {
      this.spnactive = true;
      this.factura = {};
      paquetesService
        .getLocationPaqueteOFactura(this.searchTrack)
        .then((res) => {
          this.spnactive = false;
          if (res.id != undefined) {
            //Asignación de respuesta - inBodega
            this.factura = res;
            if (this.factura.inBodega == 1) {
              this.factura.inBodega = "Si";
            } else {
              this.factura.inBodega = "No";
            }

            //transformación de texto a objeto - items
            this.factura.items = JSON.parse(res.items);
          } else if (res.status == 404) {
            this.paquete = [];
            Vue.$toast.open({
              message:
                "No encontramos paquetes o facturas que coincidan con su búsqueda.",
              type: "warning",
              duration: 7000,
            });
          } else {
            console.log();
            this.paquete = [];
            Vue.$toast.open({
              message:
                "Pollo, si lees este mensaje, llamen al programador de una vez xD.",
              type: "error",
              duration: 7000,
            });
          }
        });
    },
    verifySalida() {
      if (this.factura.pagada == 0) {
        Swal.fire({
          icon: "warning",
          title: "Awanta!",
          allowOutsideClick: false,
          text: "Est@ man no ha pagado, no le puedes dar salida a una factura si no se ha pagado.",
        });
      } else {
        Swal.fire({
          title: "io, tas seguro que le vas a dar salida a esta factura?",
          showDenyButton: true,
          confirmButtonText: `Dar Salida`,
          denyButtonText: `Ya me arrepenti`,
        }).then((result) => {
          if (result.isConfirmed) {
            Vue.$toast.open({
              message: "dando salida...",
              type: "info",
              duration: 5000,
            });
            paquetesService
              .SacarFactura({
                date: this.factura.fecha,
                fecha: moment(new Date()).format("YYYY-MM-DD"),
                facturaId: this.factura.id,
              })
              .then((res) => {
                if (res == 1) {
                  Swal.fire(
                    "Boma io!",
                    "Ya los paquetes de esta factura no estan en bodega",
                    "success"
                  );
                  this.factura.inBodega = 1;
                } else
                  Vue.$toast.open({
                    message: "Xa, algo fallo dando salida a este paquete!",
                    type: "error",
                    duration: 5000,
                  });
              })
              .catch((res) => {
                Vue.$toast.open({
                  message: "Xa, algo fallo dando salida a este paquete!",
                  type: "error",
                  duration: 5000,
                });
              });
          }
        });
      }
    },
    getAllPackages() {
      paquetesService
        .ObtenerTodosLosPaquetes()
        .then((paquetes) => {
          this.paquetes = paquetes;
        })
        .catch((error) => console.log(error));
    },
  },
  mounted() {
    this.getAllPackages();
  },
};
</script>

<style>
.sizefile {
  width: 50% !important;
}
.custom-file-label::after {
  background-color: #28a745 !important;
  color: white;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}

.v-card > .row > .col > .input-group > input {
  text-align: center !important;
  background: white;
}
.v-card > .row > .col > .input-group > textarea {
  text-align: center !important;
  background: white;
}

.v-card
  > .row
  > .col
  > .input-group
  > .input-group-prepend
  > .input-group-text
  > span {
  color: white;
  font-size: 16px;
}

.v-card
  > .row
  > .col
  > .input-group
  > .input-group-prepend
  > .input-group-text {
  background: #cc2027;
}
</style>
