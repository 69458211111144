import axios from "axios";


const AlegraService = axios.create({
  baseURL: "https://api.alegra.com/api/v1/",
  headers:{
    Authorization: "Basic c2hpcGV4MUBvdXRsb29rLmVzOjdkNmM1YjEzODVhZDI1ODc0ZDRh"
  }
});

export default AlegraService;
