<template>
  <div>
    <h3 style="color: white" class="text-center">Restablecer Contraseña</h3>
    <br />
    <form
      method="post"
      id="formresetPass"
      @submit.prevent="resetPass"
      class="needs-validation"
      autocomplete="on"
    >
      <div class="row">
        <div class="col">
          <b-form-group label="Correo Electrónico:" label-for="correo">
            <b-form-input
              id="correo"
              v-model="form.correo"
              placeholder="Introduzca su correo electrónico..."
              required
            ></b-form-input>
          </b-form-group>
        </div>
      </div>
      <div class="row justify-content-md-center">
        <div class="col-md-6 col-md-offset-2">
          <b-button type="submit" class="btn btn-block rojoShipex mb-3"
            >Enviar
            <b-spinner small v-if="spnactive" label="Spinning"></b-spinner>
          </b-button>
        </div>
      </div>
    </form>
  </div>
</template>

<script>
import * as authService from "../../services/auth.module.js";
import Swal from "sweetalert2";
export default {
  name: "ResetPassword",
  data() {
    return {
      spnactive: false,
      form: {
        correo: "",
      },
    };
  },
  methods: {
    resetPass() {
      this.spnactive = true;
      authService
        .RecuperarContrasena(this.form)
        .then((resetPass) => {
          if (resetPass.status == 200) {
            $("#formresetPass").trigger("reset");
            Swal.fire({
              icon: "success",
              title: "¡Correo Enviado!",
              allowOutsideClick: false,
              text: resetPass.message,
            }).then((result) => {
              if (result.isConfirmed) {
                window.location.href =
                  "https://www.shipexpanama.com/login";
              }
            });
          } else {
            Swal.fire({
              icon: "warning",
              title: "¡Aviso!",
              allowOutsideClick: false,
              text: resetPass.message,
            });
          }
        })
        .catch((errors) => {
          Swal.fire({
            icon: "error",
            title: "¡Error!",
            allowOutsideClick: false,
            text: "Ha ocurrido un error inesperado, favor ponerte en contacto con el personal de Shipex!",
          });
        });
    },
  },
  mounted() {
    // console.log("Montado");
  },
};
</script>

<style></style>
