<template>
  <v-app>
    <v-card class="elevation-2">
      <v-card-title class="bg-dark text-white" primary-title>
        <v-col class="text-center p-0 font-weight-bold">
          Administración de Roles para Usuarios
        </v-col>
      </v-card-title>
      <!-- Contenido -->
      <div class="p-3">
        <form @submit.prevent="guardarPermisos">
          <!-- Espacio Inputs -->
          <div class="row">
            <div class="col">
              <label for="rolType" class="form-label">Nombre de Rol: </label>
              <input
                id="rolType"
                class="form-control"
                style="height: 50px"
                type="text"
                v-model="rol.nombre"
                placeholder="Escriba el nombre del rol..."
                required
              />
            </div>
            <div class="col">
              <div id="vistasPermisos">
                <b-form-group label="Vistas de Acceso: ">
                  <b-form-checkbox-group
                    v-model="permisosArreglo"
                    :options="vistasPermisos"
                    switches
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
          <!-- Espacio Botones -->
          <div class="row">
            <div class="col">
              <input
                id="btn_save"
                type="submit"
                class="btn btn-success btn-block"
                value="Guardar Rol-Permiso"
                :hidden="showb_save == false"
                :disabled="rol.nombre === ''"
              />

              <input
                id="btn_edit"
                type="button"
                class="btn btn-warning btn-block mt-0"
                value="Editar Rol-Permiso"
                @click="editarRolUser"
                :hidden="showb_save == true"
              />
            </div>
            <div class="col">
              <input
                id="btn_cancel"
                type="button"
                class="btn btn-primary btn-block"
                @click="limpiarPermisos"
                value="Cancelar"
              />
            </div>
          </div>
        </form>
      </div>
      <div class="p-3">
        <v-card-title>
          Lista de Permisos
          <v-spacer></v-spacer>
          <v-text-field
            v-model="searchPermisos"
            label="Buscador por coincidencias"
            append-icon="mdi-magnify"
            single-line
            hide-details
          ></v-text-field>
        </v-card-title>
        <v-data-table
          :headers="headerPermisos"
          :items="permisos"
          :search="searchPermisos"
          class="border"
        >
          <template v-slot:[`item.accion`]="{ item }">
            <b-button
              small
              id="editPermisos"
              class="text-white m-1"
              variant="info"
              v-if="item.value > 1"
              @click="editarLoadPermisos(item)"
            >
              <v-icon small> mdi-pencil </v-icon>
              Editar
            </b-button>

            <b-button
              small
              id="deletePermisos"
              class="text-white m-1"
              variant="danger"
              v-if="item.value > 1"
              @click="borrarPermisos(item)"
            >
              <v-icon small> mdi-delete </v-icon>
              Eliminar
            </b-button>
          </template>
        </v-data-table>
      </div>
    </v-card>
  </v-app>
</template>

<script>
import * as clientService from "../../services/clients.module.js";
import Swal from "sweetalert2";
// import * as auth from "../../services/auth.module.js";

export default {
  name: "Adminrolusers",
  data() {
    return {
      showb_save: true,
      rol: {
        id: "",
        nombre: "",
        permisos: "",
      },
      permisosArreglo: [],
      rolesUsuarios: [],
      searchPermisos: "",
      headerPermisos: [
        { text: "ID", value: "value", align: "center" },
        { text: "Nombre de Rol", value: "text", align: "center" },
        { text: "Permisos", value: "permisos", align: "center" },
        { text: "Accion", value: "accion", align: "center" },
      ],
      permisos: [],
      vistasPermisos: [
        { text: "Manejo de Usuarios", value: "Manejo_de_Usuarios" },
        { text: "Manejo de Rol", value: "ManejorolUsuarios" },
        { text: "Administración de Roles", value: "AdminRoles_Users" },
        { text: "Facturación", value: "Facturacion" },
        { text: "Cierre de Factura", value: "CierreFactura" },
        { text: "Paquetes", value: "Paquetes_en_bodega" },
        { text: "Pagos", value: "Pagos" },
        { text: "Editar Imágenes (Home)", value: "homeEdit" },
        { text: "Historial Facturas", value: "historial" },
      ],
    };
  },
  methods: {
    Messages(res) {
      if (res.status == 200) {
        Swal.fire({
          icon: "success",
          title: "Datos Actualizados!",
          text: res.data.message,
        });
      } else if (res.status == 400) {
        Swal.fire({
          icon: "warning",
          title: "Oops...",
          text: res.data.message,
        });
      }
    },
    guardarPermisos() {
      this.rol.id = this.permisos.length;
      this.rol.permisos = JSON.stringify(this.permisosArreglo);

      clientService
        .GuardarRolUsers(this.rol)
        .then((res) => {
          if (res.status == 200) {
            this.Messages(res);
            this.limpiarPermisos();
            this.getRolCliente();
          } else {
            this.Messages(res);
            this.getRolCliente();
          }
        })
        .catch((errors) => {
          this.Messages(errors);
        });
    },
    editarLoadPermisos(item) {
      this.showb_save = false;
      this.rol.id = item.value;
      this.rol.nombre = item.text;
      this.permisosArreglo = JSON.parse(item.permisos);
    },
    editarRolUser() {
      this.rol.permisos = JSON.stringify(this.permisosArreglo);

      clientService
        .UpdateRolPermisoUser(this.rol)
        .then((res) => {
          if (res.status == 200) {
            this.Messages(res);
            this.limpiarPermisos();
            this.getRolCliente();
            this.showb_save = true;
          } else {
            this.Messages(res);
            this.getRolCliente();
          }
        })
        .catch((errors) => {
          this.Messages(errors);
        });
    },
    borrarPermisos(item) {
      clientService
        .DeleteRolUsers(item.value)
        .then((res) => {
          if (res.status == 200) {
            this.Messages(res);
            this.getRolCliente();
          } else {
            this.Messages(res);
            this.getRolCliente();
          }
        })
        .catch((errors) => {
          this.Messages(errors);
        });
    },
    limpiarPermisos() {
      this.rol = {
        id: "",
        nombre: "",
        permisos: "",
      };

      this.permisosArreglo = [];
      this.showb_save = true;
    },
    getRolCliente() {
      clientService.GetRolCliente().then((rol) => {
        this.permisos = rol;
      });
    },
  },
  mounted() {
    this.getRolCliente();
  },
};
</script>

<style></style>
