import { EnviamexService } from "./api.service";

export const GetCouiriers = () => {
  return new Promise(async resolve => {
    return EnviamexService.get("couriers")
      .then((response) => {
        resolve(response.data);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}

export const CreatePreAlertaUSA = (preAlert) => {
  return new Promise(async resolve => {
    return EnviamexService.post("pre-alert", preAlert, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        resolve(response);
      })
      .catch(({ response }) => {
        resolve(response);
      });
  });
}