<template>
  <v-app>
    <div class="card shadow border-0">
      <h5 class="card-title text-white bg-dark h4 p-4">
        <em class="ri-price-tag-3-fill"></em> Promoción
        <a
          id="cuponIn"
          class="btn btn-warning text-dark fw-bold float-right"
          @click="validCuponInsertByClient()"
          :disabled="showInfo || loadingBtn"
          :loading="loadingBtn"
          data-bs-toggle="tooltip"
          title="Una vez activado un cupón, no podrá activar otro hasta que expire."
          >Activar Cupón <em class="ri-external-link-line"></em
        ></a>
      </h5>

      <v-expand-transition>
        <div class="card-body padding-promo" v-if="showInfo">
          <v-row class="text-color-dark">
            <v-col sm="12" class="pb-0">
              <h4
                class="
                  bg-dark
                  text-white
                  rounded rounded-2
                  font-weight-semibold
                  px-2
                "
                style="width: max-content"
              >
                DETALLES DE LA PROMOCIÓN
              </h4>
            </v-col>
            <v-col lg="6" md="12" sm="12" cols="12" class="text-dark">
              <div class="w-100"><b>Nombre: </b>{{ info.nombre }}</div>
              <div class="w-100"><b>Desde: </b>{{ info.fecha_inicio }}</div>
              <div class="w-100">
                <b>Hasta: </b>{{ info.vigencia_info.nombre }}
              </div>
            </v-col>
            <v-col lg="6" md="12" sm="12" cols="12" class="text-dark">
              <div class="w-100">
                <b>Descripción:</b>
                <span>{{ info.descripcion }}</span>
              </div>
            </v-col>
          </v-row>
          <hr class="text-dark bg-dark" />
          <v-row class="text-color-dark">
            <v-col sm="12" class="pb-0 text-center">
              <h5
                class="
                  font-weight-semibold
                  bg-dark
                  text-white
                  mb-0
                  mx-auto
                  rounded rounded-2
                  px-2
                "
                style="width: max-content"
              >
                TIEMPO DE EXPIRACIÓN
              </h5>
            </v-col>
            <v-col sm="12" class="cont-temporizador pb-0">
              <div class="bloque">
                <div class="dias">
                  {{ `${contadorON ? timerText.dia : "--"}` }}
                </div>
                <p class="subtitle">DÍAS</p>
              </div>
              <div class="bloque">
                <div class="horas">
                  {{ `${contadorON ? timerText.hora : "--"}` }}
                </div>
                <p class="subtitle">HORAS</p>
              </div>
              <div class="bloque">
                <div class="minutos">
                  {{ `${contadorON ? timerText.minuto : "--"}` }}
                </div>
                <p class="subtitle">MINUTOS</p>
              </div>
              <div class="bloque">
                <div class="segundos">
                  {{ `${contadorON ? timerText.segundo : "--"}` }}
                </div>
                <p class="subtitle">SEGUNDOS</p>
              </div>
            </v-col>
          </v-row>
        </div>
      </v-expand-transition>
    </div>
  </v-app>
</template>

<script>
import * as PromocionServices from "../../services/promociones.module";
import * as GeneralHelper from "../../helper/general.module";
import Swal from "sweetalert2";

export default {
  name: "Promocion",
  data() {
    return {
      contadorON: false,
      showInfo: false,
      loadingBtn: false,
      timer: {
        dias: 0,
        horas: 0,
        minutos: 0,
        segundos: 0,
      },
      timerText: {
        dia: "",
        hora: "",
        minuto: "",
        segundo: "",
      },
      info: {
        nombre: "",
        fecha_inicio: "",
        vigencia_info: {
          nombre: "",
          valor: "",
        },
        descripcion: "",
      },
      Toast: Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }),
    };
  },
  methods: {
    iniciarTemporizador(fecha_inicio, vigencia) {
      let countDownDate = new Date(fecha_inicio + " 00:00:00");
      countDownDate.setDate(countDownDate.getDate() + vigencia);
      let this2 = this;
      this.contadorON = true;
      let x = setInterval(function () {
        let now = new Date();
        let distance = countDownDate.getTime() - now.getTime();

        this2.timer.dias = Math.floor(distance / (1000 * 60 * 60 * 24));
        this2.timerText.dia =
          this2.timer.dias < 10 ? `0${this2.timer.dias}` : this2.timer.dias;

        this2.timer.horas = Math.floor(
          (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
        );
        this2.timerText.hora =
          this2.timer.horas < 10 ? `0${this2.timer.horas}` : this2.timer.horas;

        this2.timer.minutos = Math.floor(
          (distance % (1000 * 60 * 60)) / (1000 * 60)
        );
        this2.timerText.minuto =
          this2.timer.minutos < 10
            ? `0${this2.timer.minutos}`
            : this2.timer.minutos;

        this2.timer.segundos = Math.floor((distance % (1000 * 60)) / 1000);
        this2.timerText.segundo =
          this2.timer.segundos < 10
            ? `0${this2.timer.segundos}`
            : this2.timer.segundos;
        if (distance < 0) {
          clearInterval(x);
          this2.showInfo = false;
          this2.Toast.fire({
            icon: "warning",
            title: "Cupón expirado",
          });
          this2.timerText = {
            dia: "",
            hora: "",
            minuto: "",
            segundo: "",
          };
        }
      }, 1000);
    },
    async validCuponInsertByClient() {
      const { value: cupon } = await Swal.fire({
        title: "Introduzca el cupón de la promoción!",
        input: "text",
        inputLabel: "CUPÓN",
        showCancelButton: true,
        inputValidator: (value) => {
          if (!value) {
            return "Debe escribir el cupón!";
          }
        },
      });

      if (cupon) {
        PromocionServices.ValidCuponInsertByClient({ cupon: cupon }).then(
          (res) => {
            GeneralHelper.ShowMessages(res);
            if (res.status == 200) {
              this.showInfo = true;
              this.checkIfClientHaveActivePromo();
            }
          }
        );
      }
    },
    async checkIfClientHaveActivePromo() {
      this.loadingBtn = true;
      let res = await PromocionServices.CheckIfClientHaveActivePromo();
      this.loadingBtn = false;

      if (!!res) {
        this.info = res;
        this.iniciarTemporizador(res.fecha_inicio, res.vigencia_info.valor);
        this.showInfo = true;
      } else {
        this.Toast.fire({
          icon: "info",
          title: "Sin Cupón Activo!!!",
        });
      }
    },
  },
  mounted() {
    this.checkIfClientHaveActivePromo();
    new bootstrap.Tooltip(document.getElementById("cuponIn"));
  },
};
</script>

<style>
.custom-h-card {
  min-height: 250px;
}
.padding-promo {
  padding: 40px;
}
.cont-temporizador {
  display: flex;
  justify-content: center;
}

.cont-temporizador .bloque {
  margin: 0px 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cont-temporizador .bloque div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(80, 80, 80);
  box-shadow: 0px 0px 6px 2px #727272 inset;
  color: #ffffff;
  font-size: 30px;
  font-weight: bold;
  width: 50px;
  height: 50px;
  border-radius: 5px;
}

.cont-temporizador .bloque .subtitle {
  font-size: 11px;
  font-weight: bold;
  margin: 0 !important;
}

#cuponIn.theme--light.v-btn.v-btn--disabled.v-btn--has-bg {
  background-color: rgb(231 231 231 / 55%) !important;
}
</style>