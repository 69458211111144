<template>
  <b-app>
    <b-row>
      <b-col>
        <b-carousel
          id="carousel-1"
          :interval="4000"
          background="#ababab"
          img-width="290.500"
          img-height="54.375"
          controls
          indicators
        >

          <!-- Slides with img slot -->
          <!-- Note the classes .d-block and .img-fluid to prevent browser default image alignment -->
          <b-carousel-slide v-for="i in items" :key="i.id" :img-src="i.src">
          </b-carousel-slide>

        </b-carousel>
      </b-col>
    </b-row>
  </b-app>
</template>
<script>
export default {
  name: "Carousel",
  data() {
    return {
      slide: 0,
      items: [
        {
          id:1,
          src: "img/shipex/carrucel/Aliexpress.png",
        },
        {
          id:2,
          src: "img/shipex/carrucel/Amazon.png",
        },
        {
          id:3,
          src: "img/shipex/carrucel/Boxycharm.png",
        },
        {
          id:4,
          src: "img/shipex/carrucel/Carters.png",
        },
        {
          id:5,
          src: "img/shipex/carrucel/Colourpop.png",
        },
        {
          id:6,
          src: "img/shipex/carrucel/Ebay.png",
        },
        {
          id:7,
          src: "img/shipex/carrucel/Etsy.png",
        },
        {
          id:8,
          src: "img/shipex/carrucel/HotTopic.png",
        },
        {
          id:9,
          src: "img/shipex/carrucel/Ipsy.png",
        },
        {
          id:10,
          src: "asset('img/shipex/carrucel/Macys.png",
        },
        {
          id:11,
          src: "img/shipex/carrucel/Rakuten.png",
        },
        {
          id:12,
          src: "img/shipex/carrucel/Shein.png",
        },
        {
          id:13,
          src: "img/shipex/carrucel/VictoriaSecrets.png",
        },
        {
          id:14,
          src: "img/shipex/carrucel/Walmart.png",
        },
        {
          id:15,
          src: "img/shipex/carrucel/Wish.png",
        },
      ],
    };
  },
  methods: {
    }
};
</script>