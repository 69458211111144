<template>
  <v-app>
    <v-card class="elevation-2">
      <v-card-title class="bg-dark text-white" primary-title>
        <v-col class="text-center p-0 font-weight-bold">
          Pagos de Facturas
        </v-col>
      </v-card-title>
      <!-- Inputs -->
      <b-row class="px-4">
        <b-col md class="my-auto">
          <b-form-group
            label="Introduzca código o cédula del cliente"
            label-class="font-weight-bold"
            class="mt-3 mx-auto"
            style="max-width: 356px !important"
          >
            <b-input-group>
              <b-form-input v-model="searchCliente"></b-form-input>
              <b-input-group-append>
                <b-button
                  @click="getClientByCedulaOrCode"
                  variant="outline-danger"
                  >Buscar <i class="fas fa-search"></i>
                  <b-spinner small v-if="spnactive" label="Spinning"></b-spinner
                ></b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </b-col>
        <b-col md class="">
          <b-form-group
            label="Fecha de Pago"
            label-class="font-weight-bold"
            class="mt-3 mx-auto"
          >
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaPago"
                  class="text-center"
                  label="Seleccione una fecha"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                color="#cc2027"
                elevation="15"
                v-model="fechaPago"
                :active-picker.sync="activePicker"
                :max="
                  new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
                    .toISOString()
                    .substr(0, 10)
                "
                min="2021-01-01"
              ></v-date-picker>
            </v-menu>
          </b-form-group>
        </b-col>
        <b-col md class="my-auto">
          <v-select
            v-model="selectedBank"
            :hint="`${selectedBank.name}, ${selectedBank.description}`"
            :items="banks"
            item-text="name"
            item-value="id"
            label="Seleccionar Cuenta"
            persistent-hint
            return-object
            single-line
          ></v-select>
        </b-col>
        <b-col md class="">
          <b-form-group
            label="Total a Pagar"
            label-class="font-weight-bold"
            class="mt-3 mx-auto"
            style="max-width: 356px !important"
          >
            <b-form-input v-model="payingTotal" number></b-form-input>
            <small>*Puede ajustar la cantidad manualmente</small>
          </b-form-group>
        </b-col>
      </b-row>
      <!-- Botón procesar -->
      <b-row class="px-4">
        <b-col md class="d-flex justify-content-center">
          <VueLadda
            button-class="btn btn-success btn-block"
            data-style="zoom-in"
            :loading="isLoading"
            @click="payFacturasAlegra"
          >
            Procesar Facturas
          </VueLadda>
        </b-col>
      </b-row>
      <hr />
      <!-- Info Cliente -->
      <b-row
        class="p-4 justify-content-center"
        v-if="cliente.nombre != undefined"
      >
        <b-col md="auto" class="d-flex">
          <span class="h4 m-auto">Información del Cliente:</span>
        </b-col>
        <b-col
          md="auto"
          class="text-white bg-info text-center border border-right-0 rounded-left"
        >
          <span class="h4">Nombre Completo:</span><br />
          <span class="h5">{{ cliente.nombre }} {{ cliente.apellido }}</span>
        </b-col>
        <b-col
          md="auto"
          class="text-white bg-info text-center border border-right-0 border-left-0"
        >
          <span class="h4">Cedula:</span><br />
          <span class="h5">{{ cliente.cedula }}</span>
        </b-col>
        <b-col
          md="auto"
          class="text-white bg-info text-center border border-right-0 border-left-0"
        >
          <span class="h4">Teléfono:</span><br />
          <span class="h5">{{ cliente.telefono }}</span>
        </b-col>
        <b-col
          md="auto"
          class="text-white bg-info text-center border border-left-0 rounded-right"
        >
          <span class="h4">Correo:</span><br />
          <span class="h5">{{ cliente.correo }}</span>
        </b-col>
      </b-row>
      <hr v-if="cliente.nombre != undefined" />
      <!-- Data Table -->
      <b-row class="px-4">
        <b-col>
          <v-data-table
            :headers="headers"
            :items="facturas"
            :search="search"
            :single-select="false"
            item-key="id"
            show-select
            :single-expand="true"
            :expanded.sync="expanded"
            show-expand
            class="border elevation-1 mx-auto"
            @item-selected="calculateTotalToPay"
            @toggle-select-all="calculateAllTotalToPay"
            @click:row="rowClick"
          >
            <template v-slot:[`item.pagada`]="{ item }">
              <v-icon
                v-if="item.pagada == 0"
                medium
                class="mr-2 text-danger"
                v-b-tooltip.hover
                title="Por cobrar alegra"
              >
                mdi-block-helper
              </v-icon>
              <v-icon
                v-if="item.pagada == 1"
                medium
                class="mr-2 text-success"
                v-b-tooltip.hover
                title="Pagada en alegra"
              >
                mdi-check
              </v-icon>
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length">
                <table class="table table-bordered">
                  <tr v-for="prod in item.items" v-bind:key="prod.description">
                    <td>Producto: {{ prod.description }}</td>
                    <td>Cantidad: {{ prod.quantity }}</td>
                    <td>Precio: {{ prod.price }}</td>
                  </tr>
                </table>
              </td>
            </template>
          </v-data-table>
        </b-col>
      </b-row>
    </v-card>
  </v-app>
</template>
<script>
import moment from "moment";
import VueLadda from "vue-ladda";

import * as clientService from "../../services/client.module.js";
import * as facturaService from "../../services/factura.module.js";

import Swal from "sweetalert2";

export default {
  name: "Facturacion",
  components: {
    VueLadda,
  },
  data() {
    return {
      headers: [
        { text: "Fecha", value: "date", align: "center" },
        { text: "Fecha de Vencimiento", value: "dueDate", align: "center" },
        { text: "Factura #", value: "numberTemplate.number", align: "center" },
        { text: "Total", value: "total", align: "center" },
        { text: "Pagada", value: "pagada", align: "center" },
        { text: "Detalle", value: "data-table-expand", align: "center" },
      ],
      facturas: [],
      facturasForPay: [],
      fechaPago: null,
      payingTotal: 0,
      search: "",
      searchCliente: "",
      spnactive: false,
      banks: [],
      selectedBank: {},
      isLoading: false,
      cliente: {},
      activePicker: null,
      menu: false,
      expanded: [],
    };
  },
  methods: {
    getClientByCedulaOrCode() {
      this.facturas = [];
      this.facturasForPay = [];
      this.payingTotal = 0;
      this.cliente = {};
      Vue.$toast.open({
        message: "Aguanta, primero vamos a ver si est@ man existe!",
        type: "info",
        duration: 5000,
      });
      clientService
        .GetClienteInfoByCodeOrCedula(this.searchCliente)
        .then((res) => {
          if (res.id != undefined) {
            this.cliente = res;
            Vue.$toast.open({
              message: "Offi, lo encontre pero vamos a ver si ta'a moroso",
              type: "success",
              duration: 7000,
            });
            clientService
              .GetFacturasAbiertas(res.alegra_id)
              .then((facturas) => {
                if (facturas.length > 0) {
                  facturas.forEach((f) => {
                    f.pagada = 0;
                  });
                  this.facturas = facturas;
                  Swal.fire({
                    icon: "warning",
                    title: "$hen $hen!",
                    allowOutsideClick: false,
                    text: "Est@ man tiene facturas por pagar, a cobrarle!",
                  });
                } else {
                  Vue.$toast.open({
                    message: "mmmm, est@ man no debe nada, ta clean!",
                    type: "info",
                    duration: 5000,
                  });
                }
              });
          } else if (res.status == 404) {
            Vue.$toast.open({
              message:
                "Xuzo, que va, est@ man no esta en las paginas amarillas",
              type: "error",
              duration: 7000,
            });
          }
        })
        .catch((error) => {
          Vue.$toast.open({
            message: "Xuzo, que va, est@ man no esta en las paginas amarillas",
            type: "error",
            duration: 7000,
          });
        });
    },
    calculateTotalToPay(row) {
      if (row.value) {
        this.facturasForPay.push(row.item);
      } else {
        this.facturasForPay = this.facturasForPay.filter((v) => {
          return v != row.item;
        });
      }
      this.payingTotal = 0;
      this.facturasForPay.forEach((f) => {
        this.payingTotal += f.total;
      });
    },
    calculateAllTotalToPay(row) {
      this.facturasForPay = [];
      if (row.value) {
        row.items.forEach((i) => {
          this.facturasForPay.push(i);
        });
      }

      this.payingTotal = 0;
      this.facturasForPay.forEach((f) => {
        this.payingTotal += f.total;
      });
    },
    payFacturasAlegra() {
      if (this.validarFecha()) return;

      this.isLoading = true;

      var invoices = [];
      this.facturasForPay.forEach((f) => {
        invoices.push({
          id: f.id,
          amount: f.total,
        });
      });
      var payment = {
        date: this.fechaPago,
        invoices: invoices,
        bankAccount: this.selectedBank.id,
        paymentMethod:
          this.selectedBank.type == "bank"
            ? "transfer"
            : this.selectedBank.type,
      };
      facturaService
        .PagarFactura(payment)
        .then((res) => {
          if (res.id != undefined) {
            this.MarkSuccess();
            Swal.fire({
              icon: "success",
              title: "$$ YYsada Manin!$$",
              allowOutsideClick: false,
              text:
                "El pago #" +
                res.number +
                " se efectuo correctamente sobre las facturas!",
            });
            this.UpdateFacturaShipex(res);
          } else {
            if (res.status == 400) {
              Vue.$toast.open({
                message:
                  "Alegra exploto y mando este mensaje => " + res.data.message,
                type: "error",
                duration: 15000,
              });
            }
          }
          this.isLoading = false;
        })
        .catch((error) => {
          Vue.$toast.open({
            message: "Xuzo, si este mensaje sale, llamen a los bomberos",
            type: "error",
            duration: 7000,
          });
          console.log(error);
          this.isLoading = false;
        });
    },
    UpdateFacturaShipex(payment) {
      payment.invoices.forEach((f) => {
        var invoice = this.facturasForPay.filter((fp) => {
          return fp.id == f.id;
        });
        console.log(invoice);

        if (invoice != undefined) {
          facturaService
            .UpdateFacturaPagadaShipex({
              facturaId: invoice[0].id,
              fechaFactura: invoice[0].date,
              fechaPago: payment.date,
              metodo_pago: payment.paymentMethod,
            })
            .then((res) => {
              Vue.$toast.open({
                message: "Factura actualizada en shipex! ",
                type: "success",
                duration: 2000,
              });
            });
        }
      });
      this.facturasForPay = [];
      this.payingTotal = 0;
    },
    MarkSuccess() {
      this.facturasForPay.forEach((f) => {
        this.facturas.filter((filtro) => {
          if (filtro.id == f.id) filtro.pagada = 1;
        });
      });
    },
    validarFecha() {
      if (this.fechaPago == null) {
        Swal.fire({
          icon: "error",
          title: "Awantaaa!",
          allowOutsideClick: false,
          text: "Fren, para poder pagar tienes que escoger una fecha de pago.",
        });
        return true;
      }
      return false;
    },
    getBanks() {
      facturaService.GetAccountFromAlegra().then((banks) => {
        this.selectedBank = banks[0];
        banks.forEach((b) => {
          this.banks.push(b);
        });
      });
    },
    rowClick(value) {
      this.expanded = [];
      this.expanded.push(value);
    },
  },
  mounted() {
    this.getBanks();
  },
  watch: {
    menu(val) {
      val && setTimeout(() => (this.activePicker = "YEAR"));
    },
  },
};
</script>
<style>
ul.v-date-picker-years {
  height: auto !important;
}

div.v-date-picker-table {
  height: auto !important;
  margin-bottom: 10px !important;
}

div.v-input {
  margin-top: 0px !important;
  padding-top: 0px !important;
}
</style>