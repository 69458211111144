<template>
  <v-app>
    <v-card class="elevation-2">
      <v-card-title class="bg-dark text-white" primary-title>
        <v-col class="text-center p-0 font-weight-bold"> Facturación </v-col>
      </v-card-title>
      <div class="my-4 px-4">
        <div class="row">
          <div class="col-6">
            <div class="form-group">
              <label class="h5">Cargue su archivo excel de facturas</label>
              <input
                class="form-control"
                type="file"
                @change="onLoadFile"
                accept=".xlsx,.xls"
              />
            </div>
          </div>
          <!--div class="col-6">
            <div class="form-group">
              <label class="h5">Introduzca código o cédula del cliente</label>
              <b-form-input
                @change="getClientByCedulaOrCode"
                v-model="searchCliente"
              ></b-form-input>
              <small>Presione enter para busqueda automatica</small>
            </div>
          </div-->
          <div class="col-6">
            <b-row
              class="p-4 justify-content-center"
              v-if="cliente.nombre != undefined"
            >
              <b-col
                md="auto"
                class="text-white bg-info text-center border border-right-0 rounded-left"
              >
                <span class="h4">Tipo de Plan:</span><br />
                <span class="h5">{{ cliente.tipo_plan }}</span>
              </b-col>
              <b-col
                md="auto"
                class="text-white bg-info text-center border border-right-0 rounded-left-0"
              >
                <span class="h4">Nombre Completo:</span><br />
                <span class="h5"
                  >{{ cliente.nombre }} {{ cliente.apellido }}</span
                >
              </b-col>
              <b-col
                md="auto"
                class="text-white bg-info text-center border border-right-0 border-left-0"
              >
                <span class="h4">Cedula:</span><br />
                <span class="h5">{{ cliente.cedula }}</span>
              </b-col>
              <b-col
                md="auto"
                class="text-white bg-info text-center border border-right-0 border-left-0"
              >
                <span class="h4">Teléfono:</span><br />
                <span class="h5">{{ cliente.telefono }}</span>
              </b-col>
              <b-col
                md="auto"
                class="text-white bg-info text-center border border-left-0 rounded-right"
              >
                <span class="h4">Correo:</span><br />
                <span class="h5">{{ cliente.correo }}</span>
              </b-col>
            </b-row>
          </div>
        </div>
        <b-row
          class="p-4 justify-content-center"
          v-if="cliente.nombre != undefined"
        >
          <div class="col-6">
            <div class="form-group">
              <label class="h5">Pistolee el Tracking</label>
              <input
                class="form-control"
                type="text"
                @input="onTrackingChange"
                v-model="paquete.tracking"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="h5">Coloca el Peso</label>
              <input
                class="form-control"
                type="number"
                id="txtPeso"
                v-model="paquete.peso"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="h5">Sucursal</label>
              <select
                id="selectSucursal"
                class="form-control"
                name=""
                v-model="paquete.sucursal"
              >
                <option value="0" selected>Seleccione una sucursal</option>
                <option value="1">Sucursal C.C. La Doña</option>
                <option value="11">Sucursal Colón</option>
                <option value="12">Sucursal Arraijan</option>
              </select>
              <small
                >Modifica solo si vas a facturar en otra sucursal, no es
                necesario hacerlo por cada cliente</small
              >
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="h5">Coloca el Alto</label>
              <input
                class="form-control"
                type="number"
                id="txtPeso"
                v-model="paquete.alto"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="h5">Coloca el Ancho</label>
              <input
                class="form-control"
                type="number"
                id="txtPeso"
                v-model="paquete.ancho"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="h5">Coloca el Largo</label>
              <input
                class="form-control"
                type="number"
                id="txtPeso"
                v-model="paquete.largo"
              />
            </div>
          </div>
          <div class="col-3">
            <div class="form-group">
              <label class="h5">Volumen</label>
              <input
                class="form-control"
                type="number"
                id="txtPeso"
                v-model="paquete.volumen"
              />
            </div>
          </div>
          <div class="col-12">
            <button
              class="btn btn-warning btn-block"
              @click.prevent="addPaquetePrefactura"
            >
              Anadir Paquete a Prefactura
            </button>
          </div>
        </b-row>
        <div class="row justify-content-center">
          <div class="col-sm-auto col-lg-auto">
            <div class="counter counter-secondary">
              <strong data-to="15">{{ packagesCount }}</strong>
              <label>Cantidad de Paquetes nuevos a bodega</label>
            </div>
          </div>
          <div class="col-sm-auto col-lg-auto">
            <div class="counter counter-secondary">
              <strong data-to="15">{{ invoicesCount }}</strong>
              <label>Facturas a crear</label>
            </div>
          </div>
          <div class="col-sm-auto col-lg-auto">
            <div class="counter counter-secondary">
              <strong data-to="15">{{ invoicesCreatedCount }}</strong>
              <label>Facturas creadas en alegra</label>
            </div>
          </div>
          <div class="col-sm-auto col-lg-auto">
            <div class="counter counter-secondary">
              <strong data-to="15">{{ invoicesFailedCount }}</strong>
              <label>Facturas fallidas</label>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <VueLadda
              button-class="btn btn-success btn-block"
              data-style="zoom-in"
              :loading="isLoading"
              @click="saveFacturasAlegra"
            >
              Procesar Facturas
            </VueLadda>
          </div>
        </div>
        <div class="row">
          <div class="col-md">
            <v-data-table
              :headers="headers"
              :items="facturas"
              :single-expand="true"
              :expanded.sync="expanded"
              show-expand
              :search="search"
              class="border elevation-1"
              item-key="id"
              @click:row="rowClick"
            >
              <template v-slot:expanded-item="{ headers, item }">
                <td :colspan="headers.length">
                  <table class="table table-bordered">
                    <tr
                      v-for="prod in item.items"
                      v-bind:key="prod.description"
                    >
                      <td>Producto: {{ prod.description }}</td>
                      <td>Cantidad: {{ prod.quantity }}</td>
                      <td>Precio: {{ prod.price }}</td>
                    </tr>
                  </table>
                </td>
              </template>
              <template v-slot:[`item.uploaded`]="{ item }">
                <v-icon
                  v-if="item.uploaded == 0"
                  medium
                  class="mr-2 text-danger"
                  v-b-tooltip.hover
                  title="No cargado en alegra"
                >
                  mdi-block-helper
                </v-icon>
                <v-icon
                  v-if="item.uploaded == 1"
                  medium
                  class="mr-2 text-success"
                  v-b-tooltip.hover
                  title="Cargado en alegra"
                >
                  mdi-check
                </v-icon>
              </template>
              <template v-slot:[`item.options`]="{ item }">
                <v-icon
                  medium
                  class="mr-2 text-danger"
                  v-b-tooltip.hover
                  title="Eliminar Registro"
                  @click="deletePrefactura(item)"
                >
                  mdi-trash-can
                </v-icon>
              </template>
              <template v-slot:[`item.numberTemplate`]="{ item }">
                <span>{{ item.numberTemplate.id }}</span>
              </template>
            </v-data-table>
          </div>
        </div>
      </div>
    </v-card>
  </v-app>
</template>
<script>
import XLSX from "xlsx";
import moment from "moment";
import VueLadda from "vue-ladda";
import Swal from "sweetalert2";
import * as clientService from "../../services/client.module.js";
import * as trackingService from "../../services/trakings.module.js";
import * as facturaService from "../../services/factura.module.js";
import * as tarifaService from "../../services/tarifa.module";
import * as general from "../../helper/general.module";
export default {
  name: "Facturacion",
  components: {
    VueLadda,
  },
  data() {
    return {
      headers: [
        { text: "Fecha", value: "date", align: "center" },
        { text: "Fecha Vencimiento", value: "dueDate", align: "center" },
        { text: "Nombre Cliente", value: "cliente", align: "center" },
        { text: "Cliente Alegra", value: "client", align: "center" },
        { text: "Numero de Sucursal", value: "numberTemplate" },
        { text: "Detalle", value: "data-table-expand", align: "center" },
        { text: "Cargado", value: "uploaded", align: "center" },
        { text: "Opciones", value: "options", align: "center" },
      ],
      facturas: [],
      search: "",
      expanded: [],
      invoicesCount: 0,
      invoicesCreatedCount: 0,
      invoicesFailedCount: 0,
      packagesCount: 0,
      totalPackage: 0,
      isLoading: false,
      searchCliente: "",
      cliente: {},
      paquete: {
        alto: 0,
        ancho: 0,
        largo: 0,
        peso: 0,
        volumen: 0,
        tracking: "",
      },
      tarifas: [],
    };
  },
  methods: {
    onLoadFile(evt) {
      this.file = evt.target.files ? evt.target.files[0] : null;
      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          /* Parse data */
          const bstr = e.target.result;
          const wb = XLSX.read(bstr, { type: "binary" });
          /* Get first worksheet */
          const wsname = wb.SheetNames[0];
          const ws = wb.Sheets[wsname];
          /* Convert array of arrays */
          const data = XLSX.utils.sheet_to_json(ws, { header: 1 });
          this.getTotalTrakings();
          this.ProcesarExcel(data);
        };

        reader.readAsBinaryString(this.file);
      }
    },
    async ProcesarExcel(rows) {
      Vue.$toast.open({
        message:
          "Dale con calma io, tamos cargando Excel, por favor espere...!",
        type: "info",
        duration: 15000,
      });
      var clientes = [...new Set(rows.map((s) => s[0]))];
      clientes.forEach((client, i) => {
        if (client != undefined) {
          if (!client.includes("Cliente")) {
            setTimeout(() => {
              this.isLoading = true;
              clientService
                .GetAlegraIdFromShipexByCode(client.split("-")[1])
                .then((res) => {
                  var sucursal = rows.find((look) => look[0] == client)[14];
                  var items = this.getItemsByCodigoForExcel(
                    rows,
                    sucursal,
                    client,
                    res
                  );
                  this.facturas.push({
                    date: moment(new Date()).format("YYYY-MM-DD"),
                    dueDate: moment(new Date())
                      .add(30, "days")
                      .format("YYYY-MM-DD"),
                    client: res.alegra_id,
                    status: "open",
                    items: items,
                    uploaded: 0,
                    numberTemplate: {
                      id: sucursal,
                    },
                  });
                  this.invoicesCount = this.facturas.length;
                  this.isLoading = false;
                  if (this.totalPackage == this.packagesCount) {
                    Vue.$toast.open({
                      message:
                        "Offi, se cargo el excel shill, procesa esa vaina!",
                      type: "success",
                      duration: 5000,
                    });
                  }
                });
            }, 1000 * i);
          }
        }
      });
    },
    async ProcesarLocations(factura, oldFactura) {
      trackingService.RegistrarLocation(factura).then((response) => {
        if (response.status == 201) {
          Vue.$toast.open({
            message: "Factura guardada en el invetario!",
            type: "success",
            duration: 10000,
          });
          //this.deletePrefacturaNoRefresh(oldFactura);
        } else if (response.status == 422) {
          Vue.$toast.open({
            message: "Xa, ta fallando loco!",
            type: "danger",
            duration: 5000,
          });
        }
        console.log(response);
      });
    },
    rowClick(value) {
      this.expanded = [];
      this.expanded.push(value);
    },
    getItemsByCodigoForExcel(excel, sucursal, client, clientInfo) {
      var items = [];
      excel.forEach((i) => {
        var facturable = clientInfo.tipo_facturacion == 0 ? i[9] : i[7];
        var precio =
          clientInfo.tipo_facturacion == 0 ? i[12] : clientInfo.tarifa; //verificar si siempre mandara la tarifa del plan
        var codigo_tarifa = clientInfo.tipo_facturacion == 0 ? i[10] : 34;
        if (client == i[0] && sucursal == i[14]) {
          items.push({
            id: codigo_tarifa,
            description: "Traking: " + i[2] + " P" + i[7] + "V" + i[8],
            price: precio,
            discount: 0,
            quantity: facturable,
            reference: i[2] + " Ubicado en: " + i[3],
          });
          this.packagesCount += 1;
        }
      });
      return items;
    },
    async saveFacturasAlegra() {
      this.isLoading = true;
      this.facturas.forEach((factura, i) => {
        factura.numberTemplate = factura.numberTemplate;
        console.log(factura)

        setTimeout(() => {
          if (factura.uploaded == 0) {
            facturaService
              .CrearFactura(factura)
              .then((res) => {
                if (res.data == undefined) {
                  Vue.$toast.open({
                    message:
                      "Factura creada existosamente con Numero: " +
                      res.numberTemplate.number,
                    type: "success",
                    duration: 10000,
                  });
                  this.ProcesarLocations(res, factura);
                  factura.uploaded = 1;
                  this.invoicesCreatedCount++;
                } else {
                  Vue.$toast.open({
                    message: res.data.message,
                    type: "error",
                    duration: 10000,
                  });
                  this.invoicesFailedCount++;
                }
                this.showFinalMessage();
              })
              .catch((e) => {
                Vue.$toast.open({
                  message:
                    "Ocurrio un error, por favor verifique los sistemas!",
                  type: "error",
                  duration: 10000,
                });
                this.invoicesFailedCount++;
                this.showFinalMessage();
                console.log(e);
              });
          }
        }, 1250 * i);
      });
    },
    getTotalTrakings() {
      this.packagesCount = 0;
      this.invoicesCount = 0;
      this.facturas.forEach((f) => {
        this.invoicesCount += 1;
        f.items.forEach((i) => {
          this.packagesCount += 1;
        });
      });
    },
    showFinalMessage() {
      var totalFacturas = this.invoicesCreatedCount + this.invoicesFailedCount;
      if (this.facturas.length == totalFacturas) {
        if (this.facturas.length == this.invoicesCreatedCount) {
          Swal.fire({
            icon: "success",
            title: "Bomba Io!",
            allowOutsideClick: false,
            text: "Se han procesado todas las facturas en el excel con exito!",
          });
          this.isLoading = false;
        } else if (this.invoicesFailedCount > 0) {
          Swal.fire({
            icon: "warning",
            title: "Xa, se proceso pero faltaron aw!",
            allowOutsideClick: false,
            text:
              "Se han procesado las facturas en el excel con exito, sin embargo " +
              this.invoicesFailedCount +
              " factura(s) no se pudieron procesar",
          });
          this.isLoading = false;
        }
      }
    },
    //Metodos Prefacturacion
    getClientByCedulaOrCode() {
      Vue.$toast.open({
        message: "Aguanta, primero vamos a ver si est@ man existe!",
        type: "info",
        duration: 5000,
      });
      clientService
        .GetClienteInfoByCodeOrCedula(this.searchCliente)
        .then((res) => {
          if (res.id != undefined) {
            this.cliente = res;
            Vue.$toast.open({
              message: "Offi, lo encontre, metele los paquetes",
              type: "success",
              duration: 7000,
            });
          } else if (res.status == 404) {
            Vue.$toast.open({
              message:
                "Xuzo, que va, est@ man no esta en las paginas amarillas",
              type: "error",
              duration: 7000,
            });
          }
        })
        .catch((error) => {
          Vue.$toast.open({
            message: "Xuzo, que va, est@ man no esta en las paginas amarillas",
            type: "error",
            duration: 7000,
          });
        });
    },
    onTrackingChange() {
      if (this.paquete.tracking.length >= 4) {
        setTimeout(() => {
          $("#txtPeso").focus();
        }, 250);
      }
    },
    getPreFacturas() {
      facturaService
        .GetAllPreFacturas()
        .then((facturas) => {
          this.facturas = facturas;
          this.getTotalTrakings();
        })
        .catch((e) => console.log(e));
    },
    clearObjects() {
      var oldSucursal = this.paquete.sucursal;
      this.cliente = {};
      this.paquete = {
        alto: 0,
        ancho: 0,
        largo: 0,
        peso: 0,
        volumen: 0,
        tracking: "",
      };
      this.searchCliente = "";
      this.paquete.sucursal = oldSucursal;
    },
    getTarifaForCliente(cliente, paquete) {
      var tarifa = this.tarifas.filter((t) => {
        return t.plan == cliente.tipo_plan && t.is_vol == this.isVol(paquete);
      })[0];
      if (tarifa) return tarifa;
      return { precio: 3.25, alegra_id: 7 };
    },
    isVol(paquete) {
      var diff = paquete.volumen - paquete.peso;
      if (diff >= 10) return 1;
      return 0;
    },
    addPaquetePrefactura() {
      var client = this.cliente.alegra_id;
      var tarifa = this.getTarifaForCliente(this.cliente, this.paquete);

      var sucursal = this.paquete.sucursal;
      var newItem = {
        id: tarifa.alegra_id,
        description:
          "Traking: " +
          this.paquete.tracking +
          " P" +
          this.paquete.peso +
          "V" +
          this.paquete.volumen,
        price: tarifa.precio,
        discount: 0,
        quantity: 1, //segun tarifa y segun peso volumen
      };
      var factura = this.facturas.filter((f) => {
        return (
          f.client == client && JSON.parse(f.numberTemplate).id == sucursal
        );
      })[0];

      if (factura) {
        this.facturas.forEach((f) => {
          if (f.id == factura.id) {
            f.items.push(newItem);
          }
        });
        facturaService
          .ActualizarPreFactura(factura)
          .then((res) => {
            general.ShowMessagesToastr(res);
            if (res.status == 200) {
              this.getPreFacturas();
              this.clearObjects();
            }
          })
          .catch((e) => console.log(e));
      } else {
        var items = [];
        items.push(newItem);
        var factura = {
          date: moment(new Date()).format("YYYY-MM-DD"),
          dueDate: moment(new Date()).add(30, "days").format("YYYY-MM-DD"),
          client: client,
          status: "open",
          items: items,
          uploaded: 0,
          numberTemplate: {
            id: sucursal,
          },
        };
        console.log(factura);
        this.facturas.push(factura);
        facturaService
          .CrearPreFactura(factura)
          .then((res) => {
            general.ShowMessagesToastr(res);
            if (res.status == 201) {
              this.getPreFacturas();
              this.clearObjects();
            }
          })
          .catch((e) => console.log(e));
      }
    },
    deletePrefactura(f) {
      general.toastrlong("Eliminando...", "warning");
      facturaService
        .DeletePreFactura(f.id)
        .then((res) => {
          general.ShowMessages(res);
          if (res.status == 200) {
            this.getPreFacturas();
          }
        })
        .catch((e) => console.log(e));
    },
    deletePrefacturaNoRefresh(f) {
      general.toastrlong("Eliminando...", "warning");
      facturaService
        .DeletePreFactura(f.id)
        .then((res) => {
          general.ShowMessagesToastr(res);
        })
        .catch((e) => console.log(e));
    },
    getTarifasForPlanes() {
      tarifaService
        .GetPlanesWithTarifa()
        .then((tarifas) => {
          this.tarifas = tarifas;
        })
        .catch((e) => console.log(e));
    },
  },
  mounted() {
    this.getPreFacturas();
    this.getTarifasForPlanes();
  },
};
</script>