<template>
  <v-app>
    <b-row class="text-dark">
      <b-col sm="8" class="mx-auto">
        <b-card
          class="elevation-2"
          :header="`${editMode ? 'Editar' : 'Crear'} Promoción`"
          header-bg-variant="dark"
          header-class="text-white fw-bold h4"
        >
          <hr />
          <b-form
            @submit.prevent="
              `${editMode ? actualizarPromocion() : registrarPromocion()}`
            "
            @reset.prevent="onReset()"
          >
            <b-row>
              <b-col sm="12" md="4">
                <b-form-group label="Nombre:" label-for="nombre">
                  <b-form-input
                    id="nombre"
                    v-model="promo.nombre"
                    type="text"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Código de cupón:" label-for="cupon">
                  <b-form-input
                    id="cupon"
                    v-model="promo.cupon"
                    type="text"
                    maxlength="8"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group
                  label="Cantidad de cupones:"
                  label-for="cantidad_cupones"
                >
                  <b-form-input
                    id="cantidad_cupones"
                    v-model="promo.cantidad_cupones"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Fecha de inicio:" label-for="fecha_inicio">
                  <b-form-input
                    id="fecha_inicio"
                    v-model="promo.fecha_inicio"
                    type="date"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Vigencia:" label-for="vigencia">
                  <b-form-select
                    id="vigencia"
                    v-model="promo.vigencia"
                    :options="optionsVigencia"
                    required
                  ></b-form-select>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Tipo de descuento:" label-for="tipo_promo">
                  <b-form-radio-group
                    v-model="promo.tipo_descuento"
                    :options="checkboxTipo"
                    id="tipo_promo"
                    required
                  ></b-form-radio-group>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="4">
                <b-form-group label="Valor del descuento:" label-for="valor">
                  <b-form-input
                    id="valor"
                    v-model="promo.valor_descuento"
                    type="number"
                    required
                  ></b-form-input>
                </b-form-group>
              </b-col>
              <b-col sm="12" md="8">
                <b-form-group label="Descripcion:" label-for="descripcion">
                  <b-form-textarea
                    id="descripcion"
                    v-model="promo.descripcion"
                    rows="3"
                    max-rows="6"
                    required
                  ></b-form-textarea>
                </b-form-group>
              </b-col>
              <b-col sm="6" class="mx-auto">
                <b-button-group class="w-100">
                  <b-button
                    type="submit"
                    :disabled="loading"
                    :variant="`${editMode ? 'warning' : 'danger'}`"
                  >
                    <span v-if="loading" class="text-white">
                      Cargando...<b-spinner
                        type="grow"
                        label="Spinning"
                        small
                      ></b-spinner>
                    </span>
                    <span v-else>
                      {{ editMode ? "Actualizar" : "Registrar" }}
                    </span>
                  </b-button>
                  <b-button type="reset" variant="secondary">Cancelar</b-button>
                </b-button-group>
              </b-col>
            </b-row>
          </b-form>
        </b-card>
      </b-col>
      <b-col sm="12">
        <b-card
          class="elevation-2"
          header="Lista De Promociones"
          header-bg-variant="dark"
          header-class="text-white fw-bold h4"
        >
          <hr />
          <v-data-table
            :headers="headers"
            :items="promociones"
            class="elevation-2"
          >
            <template v-slot:[`item.vigencia`]="{ item }">
              {{ obtenerVigenciaText(item) }}
            </template>
            <template v-slot:[`item.tipo_descuento`]="{ item }">
              {{ obtenerDescuentoText(item) }}
            </template>
            <template v-slot:[`item.opciones`]="{ item }">
              <v-btn
                title="Editar"
                elevation="1"
                color="warning"
                @click="asignarDatosEdit(item)"
                icon
                tile
                outlined
              >
                <i class="ri-edit-2-fill"></i>
              </v-btn>
              <v-btn
                :title="item.estado == 0 ? 'Activar' : 'Desactivar'"
                elevation="1"
                @click="actualizarEstadoDePromocion(item)"
                :color="item.estado == 0 ? 'success' : 'red'"
                icon
                outlined
                tile
              >
                <i class="ri-checkbox-circle-fill" v-if="item.estado == 0"></i>
                <i class="ri-close-circle-fill" v-else></i>
              </v-btn>
            </template>
          </v-data-table>
        </b-card>
      </b-col>
    </b-row>
  </v-app>
</template>

<script>
import * as PromocionesService from "../../services/promociones.module";
import * as GeneralHelper from "../../helper/general.module";
import Swal from "sweetalert2";

export default {
  name: "Adminpromociones",
  data() {
    return {
      editMode: false,
      promo: {
        nombre: "",
        cupon: "",
        descripcion: "",
        cantidad_cupones: null,
        fecha_inicio: "",
        vigencia: "",
        tipo_descuento: "",
        valor_descuento: "",
      },
      optionsVigencia: [],
      checkboxTipo: [
        {
          value: 1,
          text: "Dinero",
        },
        {
          value: 2,
          text: "Porcentual",
        },
        {
          value: 3,
          text: "Libra",
        },
      ],
      loading: false,
      promociones: [],
      headers: [
        {
          text: "Nombre",
          value: "nombre",
        },
        {
          text: "Cupón",
          value: "cupon",
        },
        {
          text: "Descripción",
          value: "descripcion",
        },
        {
          text: "Cantidad de cupones",
          value: "cantidad_cupones",
        },
        {
          text: "Fecha de inicio",
          value: "fecha_inicio",
        },
        {
          text: "Vigencia",
          value: "vigencia",
        },
        {
          text: "Tipo descuento",
          value: "tipo_descuento",
        },
        {
          text: "valor del descuento",
          value: "valor_descuento",
        },
        {
          text: "",
          value: "opciones",
        },
      ],
      Toast: Swal.mixin({
        toast: true,
        position: "bottom-end",
        showConfirmButton: false,
        timer: 3000,
        timerProgressBar: true,
        didOpen: (toast) => {
          toast.addEventListener("mouseenter", Swal.stopTimer);
          toast.addEventListener("mouseleave", Swal.resumeTimer);
        },
      }),
    };
  },
  watch: {
    "promo.cupon"(val) {
      this.promo.cupon = val.toUpperCase();
    },
  },
  methods: {
    actualizarEstadoDePromocion(item) {
      Swal.fire({
        title: "Esta seguro?",
        text: "se cambiara el estado de la promoción!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Cambiar estado!",
      }).then((result) => {
        if (result.isConfirmed) {
          this.Toast.fire({
            icon: "info",
            title: "Procesando, por favor espere!!",
          });
          PromocionesService.ActualizarEstadoPromocion({
            id: item.id,
            estado: !item.estado,
          })
            .then((res) => {
              this.obtenerPromociones();
              GeneralHelper.ShowMessages(res);
            })
            .catch((res) => console.log(res));
        }
      });
    },
    obtenerDescuentoText(item) {
      let res = "";
      this.checkboxTipo.forEach((element) => {
        if (item.vigencia == element.value) {
          res = element.text;
        }
      });
      return res;
    },
    obtenerVigenciaText(item) {
      let res = "";
      this.optionsVigencia.forEach((element) => {
        if (item.vigencia == element.value) {
          res = element.text;
        }
      });
      return res;
    },
    asignarDatosEdit(item) {
      this.editMode = true;
      this.promo = item;
    },
    actualizarPromocion() {
      this.loading = true;
      PromocionesService.ActualizarPromocion(this.promo)
        .then((res) => {
          GeneralHelper.ShowMessages(res);
          this.obtenerPromociones();
          this.loading = false;
          if (res.status == 200) {
            this.onReset();
          }
        })
        .catch((res) => console.log(res));
    },
    registrarPromocion() {
      this.loading = true;
      PromocionesService.RegistrarNuevaPromocion(this.promo)
        .then((res) => {
          GeneralHelper.ShowMessages(res);
          this.obtenerPromociones();
          this.loading = false;
          if (res.status == 200) {
            this.onReset();
          }
        })
        .catch((res) => console.log(res));
    },
    onReset() {
      this.editMode = false;
      this.promo = {
        nombre: "",
        cupon: "",
        descripcion: "",
        cantidad_cupones: null,
        fecha_inicio: "",
        vigencia: "",
      };
      this.loading = false;
    },
    async obtenerPromociones() {
      this.promociones = await PromocionesService.GetPromociones();
    },
    async obtenerVigenciasDePromociones() {
      this.optionsVigencia =
        await PromocionesService.GetVigenciasDePromociones();
    },
  },
  mounted() {
    this.obtenerVigenciasDePromociones();
    this.obtenerPromociones();
  },
};
</script>

<style>
</style>